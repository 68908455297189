/* eslint-disable max-len */
import { call, put, takeLatest, all } from 'redux-saga/effects';

import {
  GET_TRANSACTION_DETAILS,
  GET_TRANSACTION_DETAILS_SUCCESS,
  GET_TRANSACTION_DETAILS_ERROR,
  GET_ALL_DETAILS,
  GET_ALL_DETAILS_SUCCESS,
  GET_ALL_DETAILS_ERROR,
  SET_SHOW_GUIDE,
  TRANSFER_STASHCASH_SUCCESS,
  TRANSFER_STASHCASH_ERROR,
  TRANSFER_STASHCASH,
  GET_BANK_DETAILS_SUCCESS,
  GET_BANK_DETAILS_ERROR,
  GET_BANK_DETAILS,
} from './actionTypes';

import {
  getStashCashApiCall,
  getGamesApiCall,
  getTransactionDetailsApiCall,
  setShowGuideApiCall,
  transferStashcashApiCall,
  getBankDetailsApiCall,
} from './services';

const commonError = 'Something went wrong, please try again later';

function* getTransactionDetails({ payload }) {
  try {
    const response = yield call(getTransactionDetailsApiCall, { payload });
    if (response.status === 200) {
      yield put({
        type: GET_TRANSACTION_DETAILS_SUCCESS,
        payload: { data: response?.data?.data?.history, concatData: payload?.concatData },
      });
    } else if (response.status > 200 && response.status < 500) {
      yield put({ type: GET_TRANSACTION_DETAILS_ERROR, payload: { error: response.data?.error || commonError } });
    } else {
      yield put({ type: GET_TRANSACTION_DETAILS_ERROR, payload: { error: commonError } });
    }
  } catch (error) {
    yield put({ type: GET_TRANSACTION_DETAILS_ERROR, payload: { error: commonError } });
  }
}

function* setShowGuide({ payload }) {
  yield call(setShowGuideApiCall, { payload });
}

function* getAllDetails() {
  try {
    const [response1, response2] = yield all([call(getStashCashApiCall), call(getGamesApiCall)]);
    if (response1.status === 200 && response2.status === 200) {
      yield put({
        type: GET_ALL_DETAILS_SUCCESS,
        payload: {
          getStashCashApiResponse: response1.data?.data,
          getGamesApiResponse: response2.data,
        },
      });
    } else {
      yield put({
        type: GET_ALL_DETAILS_ERROR,
        payload: { error: response1.data?.error || response2.data?.error || commonError },
      });
    }
  } catch (error) {
    yield put({ type: GET_ALL_DETAILS_ERROR, payload: { error: commonError } });
  }
}

function* transferStashcash({ payload }) {
  try {
    const response = yield call(transferStashcashApiCall, { payload });
    if (response.status === 200) {
      yield put({ type: TRANSFER_STASHCASH_SUCCESS, payload: response?.data });
    } else if (response.status > 200 && response.status < 500) {
      yield put({ type: TRANSFER_STASHCASH_ERROR, payload: { error: response.data?.error || commonError } });
    } else {
      yield put({ type: TRANSFER_STASHCASH_ERROR, payload: { error: commonError } });
    }
  } catch (error) {
    yield put({ type: TRANSFER_STASHCASH_ERROR, payload: { error: commonError } });
  }
}

function* getBankDetails() {
  try {
    const response = yield call(getBankDetailsApiCall);
    if (response.status === 200) {
      yield put({ type: GET_BANK_DETAILS_SUCCESS, payload: response?.data?.data });
    } else if (response.status > 200 && response.status < 500) {
      yield put({ type: GET_BANK_DETAILS_ERROR, payload: { error: response.data?.error || commonError } });
    } else {
      yield put({ type: GET_BANK_DETAILS_ERROR, payload: { error: commonError } });
    }
  } catch (error) {
    yield put({ type: GET_BANK_DETAILS_ERROR, payload: { error: commonError } });
  }
}

function* GameEngineSaga() {
  yield takeLatest(GET_ALL_DETAILS, getAllDetails);
  yield takeLatest(SET_SHOW_GUIDE, setShowGuide);
  yield takeLatest(GET_TRANSACTION_DETAILS, getTransactionDetails);
  yield takeLatest(TRANSFER_STASHCASH, transferStashcash);
  yield takeLatest(GET_BANK_DETAILS, getBankDetails);
}

export default GameEngineSaga;
