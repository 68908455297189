import {
  HANDLE_FLAG,
  LOAN_RESTRUCTURE_DETAILS,
  LOAN_RESTRUCTURE_DETAILS_ERROR,
  LOAN_RESTRUCTURE_DETAILS_SUCCESS,
  LOAN_RESTRUCTURE_SEND_OTP,
  LOAN_RESTRUCTURE_SEND_OTP_ERROR,
  LOAN_RESTRUCTURE_SEND_OTP_SUCCESS,
  VERIFY_LOAN_RESTRUCTURE,
  VERIFY_LOAN_RESTRUCTURE_ERROR,
  VERIFY_LOAN_RESTRUCTURE_SUCCESS,
} from './constants';

const initialState = {
  flag: 0,
  loader: true,
  error: '',
  loanRestructureDetails: [],
  token: null,
  message: '',
};

const LoanRestructureReducer = (state = initialState, action = {}) => {
  const { type, payload } = action;
  switch (type) {
    case LOAN_RESTRUCTURE_DETAILS:
      return {
        ...state,
        error: '',
        token: payload,
      };
    case LOAN_RESTRUCTURE_DETAILS_SUCCESS:
      const data = [
        { name: 'Pending Loan Amount', value: payload?.pending_loan_amount },
        { name: 'Pending EMI', value: payload?.pending_emi },
        { name: 'Pending Loan Tenure', value: payload?.pending_loan_tenure },
        { name: 'Revised EMI', value: payload?.revised_emi },
        { name: 'Revised Loan Tenure', value: payload?.revised_loan_tenure },
      ];
      return {
        ...state,
        loanRestructureDetails: data,
        loader: false,
        error: '',
      };
    case LOAN_RESTRUCTURE_DETAILS_ERROR:
      return {
        ...state,
        loader: false,
        flag: payload?.flag,
        error: payload?.error,
      };
    case LOAN_RESTRUCTURE_SEND_OTP:
      return {
        ...state,
        error: '',
        loader: true,
      };
    case LOAN_RESTRUCTURE_SEND_OTP_SUCCESS:
      return {
        ...state,
        loader: false,
        flag: payload?.flag,
        error: '',
      };
    case LOAN_RESTRUCTURE_SEND_OTP_ERROR:
      return {
        ...state,
        loader: false,
        error: payload?.error,
        flag: payload?.flag,
      };
    case VERIFY_LOAN_RESTRUCTURE:
      return {
        ...state,
        loader: true,
        error: '',
      };
    case VERIFY_LOAN_RESTRUCTURE_SUCCESS:
      return {
        ...state,
        flag: payload?.flag,
        loader: false,
        error: '',
        message: payload?.message,
      };
    case VERIFY_LOAN_RESTRUCTURE_ERROR:
      return {
        ...state,
        error: payload,
        loader: false,
      };
    case HANDLE_FLAG:
      return {
        ...state,
        flag: payload,
      };
    default:
      return state;
  }
};

export default LoanRestructureReducer;
