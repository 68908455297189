/* eslint-disable max-lines */
import axios from 'axios';

const BASE_URL = process.env.REACT_APP_BASE_API_URL;
const REWARDS_BASE_URL = process.env.REACT_APP_BASE_GAME_ENGINE_URL;

export const offersListApiCall = async () => {
  try {
    const response = await axios.get(`${BASE_URL}magneto/offer/`, {
      headers: {
        auth_token: localStorage.getItem('auth_token'),
        device_id: localStorage.getItem('device_id'),
      },
    });
    return response;
  } catch (error) {
    return error;
  }
};

export const voucherDetailsApiCall = async ({ uid }) => {
  try {
    const response = await axios.get(`${BASE_URL}magneto/offer/${uid}`, {
      headers: {
        auth_token: localStorage.getItem('auth_token'),
        device_id: localStorage.getItem('device_id'),
      },
    });
    return response;
  } catch (error) {
    return error;
  }
};

export const getCartApiCall = async () => {
  try {
    const response = await axios.get(`${BASE_URL}magneto/offer/cart`, {
      headers: {
        auth_token: localStorage.getItem('auth_token'),
        device_id: localStorage.getItem('device_id'),
        'Cache-Control': 'no-cache',
      },
    });
    return response;
  } catch (error) {
    return error;
  }
};

export const updateCartApiCall = async ({ payload }) => {
  try {
    const response = await axios.put(`${BASE_URL}magneto/offer/update-cart`, payload, {
      headers: {
        auth_token: localStorage.getItem('auth_token'),
        device_id: localStorage.getItem('device_id'),
        'Content-Type': 'application/json',
      },
    });
    return response;
  } catch (error) {
    return error;
  }
};

export const getPaymentLinkApiCall = async (payload) => {
  try {
    const params = [];
    if (payload?.offerDiscount) params.push(`?offerDiscount=${payload.offerDiscount}`);
    const queryParams = params.join('');
    const response = await axios.get(`${BASE_URL}magneto/offer/payment-link${queryParams}`, {
      headers: {
        auth_token: localStorage.getItem('auth_token'),
        device_id: localStorage.getItem('device_id'),
      },
    });
    return response;
  } catch (error) {
    return error;
  }
};

export const checkOKycApiCall = async () => {
  try {
    const response = await axios.get(`${BASE_URL}magneto/offer/check-okyc`, {
      headers: {
        auth_token: localStorage.getItem('auth_token'),
        device_id: localStorage.getItem('device_id'),
      },
    });
    return response;
  } catch (error) {
    return error;
  }
};

export const paymentStatusApiCall = async ({ orderUidForPaymentStatus }) => {
  try {
    const response = await axios.get(`${BASE_URL}magneto/offer/payment-status/${orderUidForPaymentStatus}`, {
      headers: {
        auth_token: localStorage.getItem('auth_token'),
        device_id: localStorage.getItem('device_id'),
      },
    });
    return response;
  } catch (error) {
    return error;
  }
};

export const ordersApiCall = async () => {
  try {
    const response = await axios.get(`${BASE_URL}magneto/offer/orders`, {
      headers: {
        auth_token: localStorage.getItem('auth_token'),
        device_id: localStorage.getItem('device_id'),
      },
    });
    return response;
  } catch (error) {
    return error;
  }
};

export const locDiscountApiCall = async (payload) => {
  try {
    const response = await axios.post(`${BASE_URL}magneto/offer/loc-discount`, payload, {
      headers: {
        auth_token: localStorage.getItem('auth_token'),
        device_id: localStorage.getItem('device_id'),
      },
    });
    return response;
  } catch (error) {
    return error;
  }
};

export const selectBestOfferApiCall = async (payload) => {
  try {
    const response = await axios.post(`${REWARDS_BASE_URL}rewards/v1/selectBestOffers`, payload, {
      headers: {
        auth_token: localStorage.getItem('auth_token'),
        device_id: localStorage.getItem('device_id'),
      },
    });
    return response;
  } catch (error) {
    return error;
  }
};
