import {
  GET_POSTS,
  GET_POSTS_SUCCESS,
  GET_POSTS_FAIL,
} from "../utils/constant";

const initialState = {
  lists: [],
  listItem: {},
  loadingList: false,
  loadinglistItem: false,
  error: {
    message: "",
  },
};

const listReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_POSTS:
      state = { ...state, loadingList: true };
      break;
    case GET_POSTS_SUCCESS:
      state = { ...state, lists: action.payload, loadingList: false };
      break;
    case GET_POSTS_FAIL:
      state = {
        ...state,
        error: {
          message: "Error",
        },
        loadingList: false,
      };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default listReducer;
