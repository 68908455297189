import { call, takeLatest } from 'redux-saga/effects';

import { SEND_EVENT_TO_CLEVERTAP } from '../utils/constant';
import { clevertapEventApiCall } from '../service/clevertapService';

function* sendEventToClevertap({ payload }) {
  try {
    yield call(clevertapEventApiCall, payload);
  } catch (error) {
    // no action needed
  }
}

function* ClevertapSaga() {
  yield takeLatest(SEND_EVENT_TO_CLEVERTAP, sendEventToClevertap);
}

export default ClevertapSaga;
