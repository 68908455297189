const initialState = {
  cityData: {},
}

const CityWiseIplReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case 'CITY_DATA':
      const cityName = payload?.city
      const jsonArray = payload?.jsonData
      const cityIndex = jsonArray?.findIndex(
        (city) => city.city_route === cityName,
      )
      let cityData
      if (cityIndex > -1) {
        cityData = jsonArray[cityIndex]
      } else {
        cityData = jsonArray[0]
      }
      state = { ...state, cityData: cityData }
      break
    default:
      state = { ...state }
      break
  }
  return state
}

export default CityWiseIplReducer
