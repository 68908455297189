import { Helmet } from 'react-helmet-async';

export default function HomeScripts() {
  return (
    <Helmet>
      "
      <script type="application/ld+json">
        {`
            "@context": "https://schema.org",
            "@type": "Organization",
            "name": "Stashfin",
            "url": "https://www.stashfin.com/",
            "sameAs": [
                "https://www.facebook.com/stashfin.official",
                "https://www.instagram.com/stashfin_/",
                "https://www.linkedin.com/company/stashfin/",
                "https://youtube.com/channel/UCyjtuSfqPbbHcu07CZ1eqEg"
            ]
            `}
      </script>
      " "
      <script type="application/ld+json">
        {`
            "@context": "https://schema.org/", 
            "@type": "BreadcrumbList", 
            "itemListElement": [{
                "@type": "ListItem", 
                "position": 1, 
                "name": "Home",
                "item": "https://www.stashfin.com/"   
            }]
            `}
      </script>
      " "
      <script type="application/ld+json">
        {`
            "@context": "http://schema.org",
                "@type": "WebPage",
                "name": "Stashfin",
                "description": "Apply for instant personal loans online from Stashfin and get easy loan approval at low-interest rates. Register online and get the flexibility to repay in easy installments of 3 - 24 months.",
                "publisher": {
                    "@type": "ProfilePage",
                    "name": "https://www.stashfin.com/"
                }    
            `}
      </script>
      "
    </Helmet>
  );
}
