import { call, put, takeLatest, select } from "redux-saga/effects";
import { GET_BASICDETAIL,GET_BASICDETAIL_URL_PARAM,POST_BASICDETAIL, POST_BASICDETAIL_SUCCESS, POST_BASICDETAIL_URL_PARAM} from "../../../utils/constant";
import {basicDetailApiCall, postBasicDetailApiCall} from "../services";

import {
    basicDetailsgetSuccess,
    basicDetailsgetFail,
    basicDetailsPostSuccess,
    basicDetailsPostFail
  } from "../actions";

  function* onbasicDetailsget() {
    try {
        let basicdetail = yield call(basicDetailApiCall);
        if (basicdetail?.status === true) {
          yield put(basicDetailsgetSuccess(basicdetail?.results));
        } else{
          yield put(basicDetailsgetFail(basicdetail));
        }
      } catch (error) {
        yield put(basicDetailsgetFail(error));
      }
  }

  function* onbasicDetailspost(data) {
    try {
        let basicdetail = yield call(postBasicDetailApiCall,data.payload);
        if (basicdetail.data.status === true) {
          yield put({type:POST_BASICDETAIL_SUCCESS,payload:basicdetail.data.results});
        }else{
          yield put(basicDetailsPostFail(basicdetail));
        }
      } catch (error) {
        yield put(basicDetailsPostFail(error));
      }
  }

  export default function* onbasicDetailsagas() {
    yield takeLatest(GET_BASICDETAIL, onbasicDetailsget);
    yield takeLatest(POST_BASICDETAIL, onbasicDetailspost);
  }
