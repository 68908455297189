export const SET_CURRENT_SCREEN = 'SET_CURRENT_SCREEN';

export const GET_DEALS_AND_OFFERS_LIST = 'GET_DEALS_AND_OFFERS_LIST';
export const GET_DEALS_AND_OFFERS_LIST_SUCCESS = 'GET_DEALS_AND_OFFERS_LIST_SUCCESS';
export const GET_DEALS_AND_OFFERS_LIST_FAILURE = 'GET_DEALS_AND_OFFERS_LIST_FAILURE';

export const GET_VOUCHER_DETAILS = 'GET_VOUCHER_DETAILS';
export const GET_VOUCHER_DETAILS_SUCCESS = 'GET_VOUCHER_DETAILS_SUCCESS';
export const GET_VOUCHER_DETAILS_FAILURE = 'GET_VOUCHER_DETAILS_FAILURE';

export const GET_CART_DETAILS = 'GET_CART_DETAILS';
export const GET_CART_DETAILS_SUCCESS = 'GET_CART_DETAILS_SUCCESS';
export const GET_CART_DETAILS_FAILURE = 'GET_CART_DETAILS_FAILURE';

export const UPDATE_CART = 'UPDATE_CART';
export const UPDATE_CART_SUCCESS = 'UPDATE_CART_SUCCESS';
export const UPDATE_CART_FAILURE = 'UPDATE_CART_FAILURE';

export const GET_PAYMENT_DETAILS = 'GET_PAYMENT_DETAILS';
export const GET_PAYMENT_DETAILS_SUCCESS = 'GET_PAYMENT_DETAILS_SUCCESS';
export const GET_PAYMENT_DETAILS_FAILURE = 'GET_PAYMENT_DETAILS_FAILURE';

export const GET_PAYMENT_LINK_FOR_DEALS = 'GET_PAYMENT_LINK_FOR_DEALS';
export const GET_PAYMENT_LINK_FOR_DEALS_SUCCESS = 'GET_PAYMENT_LINK_FOR_DEALS_SUCCESS';
export const GET_PAYMENT_LINK_FOR_DEALS_FAILURE = 'GET_PAYMENT_LINK_FOR_DEALS_FAILURE';

export const CHECK_OKYC_SUCCESS = 'CHECK_OKYC_SUCCESS';
export const CHECK_OKYC_FAILURE = 'CHECK_OKYC_FAILURE';

export const GET_ORDER_DETAILS = 'GET_ORDER_DETAILS';
export const GET_ORDER_DETAILS_SUCCESS = 'GET_ORDER_DETAILS_SUCCESS';
export const GET_ORDER_DETAILS_FAILURE = 'GET_ORDER_DETAILS_FAILURE';

export const GET_ORDERS_LIST = 'GET_ORDERS_LIST';
export const GET_ORDERS_LIST_SUCCESS = 'GET_ORDERS_LIST_SUCCESS';
export const GET_ORDERS_LIST_FAILURE = 'GET_ORDERS_LIST_FAILURE';

export const SET_ORDER_DETAILS = 'SET_ORDER_DETAILS';
export const SET_PAYMENT_STATUS_API_POLLING = 'SET_PAYMENT_STATUS_API_POLLING';
export const SET_IS_CART_ALERT_OPEN = 'SET_IS_CART_ALERT_OPEN';

export const LOC_DISCOUNT = 'LOC_DISCOUNT';
export const LOC_DISCOUNT_SUCCESS = 'LOC_DISCOUNT_SUCCESS';
export const LOC_DISCOUNT_FAILURE = 'LOC_DISCOUNT_FAILURE';

export const CHECK_DISCOUNT_ON_CART_ITEM = 'CHECK_DISCOUNT_ON_CART_ITEM';
