import {
  BACK_FROM_BONDS,
  GET_ALL_GRIP_BONDS,
  GET_ALL_GRIP_BONDS_FAILURE,
  GET_ALL_GRIP_BONDS_SUCCESS,
  GET_BOND_DETAILS,
  GET_BOND_DETAILS_FAILURE,
  GET_BOND_DETAILS_SUCCESS,
  GET_GRIP_REDIRECTION_LINK,
  GET_GRIP_REDIRECTION_LINK_FAILURE,
  GET_GRIP_REDIRECTION_LINK_SUCCESS,
  GET_ORDERS,
  GET_ORDERS_FAILURE,
  GET_ORDERS_SUCCESS,
  GET_USER_CONSENT_STATUS,
  GET_USER_CONSENT_STATUS_FAILURE,
  GET_USER_CONSENT_STATUS_SUCCESS,
  IS_BACK,
  REDEEM_NOW,
  REDEEM_NOW_FAILURE,
  REDEEM_NOW_SUCCESS,
  REGISTER_USER_TO_GRIP,
  REGISTER_USER_TO_GRIP_FAILURE,
  REGISTER_USER_TO_GRIP_SUCCESS,
  RESET_REDEEM,
  SET_BOND_DETAILS,
  SET_CURRENT_SCREEN,
  SET_ORDERS,
  SET_STASHFIN_APPROVE_STATUS,
  SET_USER_POPUP_DETAILS,
  YUBI_REDIRECT,
  YUBI_REDIRECT_FAILURE,
  YUBI_REDIRECT_SUCCESS,
} from './actionTypes';

const initialState = {
  currentScreen: '',
  userDetails: {},
  userDetailsError: '',
  stashfinApprovedUser: true,
  userPopupDetails : {},
  registeredUser: '',
  assetsWithCategoriesList: [],
  userKycDone: false,
  bondListError: '',
  bondId: '',
  bondDetailsFromClickedBond: {},
  bondDetails: {},
  bondDetailsError: '',
  redirectionLink: '',
  redirectionError: '',
  myOrders: '',
  myOrdersError: '',
  loader: false,
  redeemStatus : "",
  redeemError : "",
  yubi_redirectUrl: "",
  is_back : false
};

const WealthReducer = (state = initialState, action = {}) => {
  const { type, payload } = action;

  switch (type) {
    case SET_CURRENT_SCREEN:
      return {
        ...state,
        currentScreen: payload,
      };

    case SET_STASHFIN_APPROVE_STATUS:
      return {
        ...state,
        stashfinApprovedUser: payload,
      };
      case SET_USER_POPUP_DETAILS:
      return {
        ...state,
        userPopupDetails: payload,
      };
    case GET_USER_CONSENT_STATUS:
      return {
        ...state,
        loader: true,
      };
    case GET_USER_CONSENT_STATUS_SUCCESS:
      return {
        ...state,
        userDetails: payload,
        loader: false,
      };
    case GET_USER_CONSENT_STATUS_FAILURE:
      return {
        ...state,
        userDetailsError: payload.error,
        loader: false,
      };
    case REGISTER_USER_TO_GRIP:
      return {
        ...state,
        // userBasicDetails: payload,
        loader: true,
      };
    case REGISTER_USER_TO_GRIP_SUCCESS:
      return {
        ...state,
        registeredUser: payload,
        is_back : false,
        loader: false,
      };
    case REGISTER_USER_TO_GRIP_FAILURE:
      return {
        ...state,
        registeredUser: payload.error,
        loader: false,
      };
    case GET_ALL_GRIP_BONDS:
      return {
        ...state,
        loader: true,
      };
    case GET_ALL_GRIP_BONDS_SUCCESS:
      return {
        ...state,
        assetsWithCategoriesList: payload.data.assets,
        userKycDone: payload.data.userKycStatus && payload.data.userKycStatus !== '',
        loader: false,
      };
    case GET_ALL_GRIP_BONDS_FAILURE:
      return {
        ...state,
        bondListError: payload.error,
        loader: false,
      };
    case GET_BOND_DETAILS:
      return {
        ...state,
        bondId: payload?.assetID,
        bondDetailsFromClickedBond: payload,
        loader: true,
      };
    case GET_BOND_DETAILS_SUCCESS:
      return {
        ...state,
        bondDetails: payload,
        loader: false,
      };
    case GET_BOND_DETAILS_FAILURE:
      return {
        ...state,
        bondDetailsError: payload.error,
        loader: false,
      };
    case SET_BOND_DETAILS:
      return {
        ...state,
        bondId: '',
        bondDetailsFromClickedBond: {},
        bondDetails: {},
      };
    case GET_GRIP_REDIRECTION_LINK:
      return {
        ...state,
        loader: true,
      };
    case GET_GRIP_REDIRECTION_LINK_SUCCESS:
      return {
        ...state,
        redirectionLink: payload,
        // loader: false,
      };
    case GET_GRIP_REDIRECTION_LINK_FAILURE:
      return {
        ...state,
        redirectionError: payload.error,
        loader: false,
      };
    case GET_ORDERS:
      return {
        ...state,
        loader: true,
      };
    case GET_ORDERS_SUCCESS:
      return {
        ...state,
        myOrders: payload,
        loader: false,
      };
    case GET_ORDERS_FAILURE:
      return {
        ...state,
        myOrdersError: payload.error,
        loader: false,
      };
    case SET_ORDERS:
      return {
        ...state,
        myOrders: '',
      };

    case REDEEM_NOW:
      return {
        ...state,
        loader: true,
      };
    case REDEEM_NOW_SUCCESS:
      return {
        ...state,
        redeemStatus: payload,
        loader: false,
      };
    case REDEEM_NOW_FAILURE:
      return {
        ...state,
        redeemStatus: payload.status,
        redeemError: payload.error,
        loader: false,
      };
    case RESET_REDEEM:
      return {
        ...state,
        redeemStatus: '',
        redeemError: '',
        loader: false,
      };

      case SET_STASHFIN_APPROVE_STATUS:
        return {
          ...state,
          stashfinApprovedUser: payload,
        };
      case YUBI_REDIRECT:
        return {
          ...state,
          loader: true,
        };
      case YUBI_REDIRECT_SUCCESS:
        return {
          ...state,
          yubi_redirectUrl: payload,
        };
      case YUBI_REDIRECT_FAILURE:
        return {
          ...state,
          loader: false,
        };
      case IS_BACK:
        return {
          ...state,
           is_back : payload
        };
    default:
      return state;
  }
};

export default WealthReducer;
