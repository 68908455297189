import { takeLatest, put, call } from "redux-saga/effects";
import { GET_POSTS } from "../utils/constant";

import { getPostsSuccess, getPostsFail } from "../actions/list.actions";

import { getPosts } from "../service/api";

function* onGetPosts() {
  try {
    const response = yield call(getPosts);
    yield put(getPostsSuccess(response));
  } catch (error) {
    yield put(getPostsFail(error.response));
  }
}

export default function* listSagas() {
  yield takeLatest(GET_POSTS, onGetPosts);
}
