import { call, put, select, takeLatest } from 'redux-saga/effects';
import {
  GET_LEAD_DETAILS,
  GET_LEAD_DETAILS_SUCCESS,
  GET_LEAD_DETAILS_ERROR,
  SUBMIT_FORM,
  SUBMIT_FORM_SUCCESS,
  SUBMIT_FORM_ERROR,
} from './actionTypes';
import { leadDetailApiCall, leadSubmitApiCall } from './services';

const commonError = 'Something went wrong, please try again later';
const stateValue = (state) => state.GoldWaitlistReducer;

function* getLeadDetails() {
  try {
    const { token, deviceId } = yield select(stateValue);
    const response = yield call(leadDetailApiCall, { token, deviceId });
    if (response.status === 200) {
      yield put({ type: GET_LEAD_DETAILS_SUCCESS, payload: response?.data?.data });
    } else if (response.status > 200 && response.status < 500) {
      yield put({ type: GET_LEAD_DETAILS_ERROR, payload: { error: response?.data?.error_message || commonError } });
    } else {
      yield put({ type: GET_LEAD_DETAILS_ERROR, payload: { error: commonError } });
    }
  } catch (err) {
    yield put({ type: GET_LEAD_DETAILS_ERROR, payload: { error: commonError } });
  }
}

function* submitForm({ payload }) {
  try {
    const { token, deviceId, utmData } = yield select(stateValue);
    const response = yield call(leadSubmitApiCall, { token, deviceId, utmData, payload });
    if (response.status === 200) {
      yield put({ type: SUBMIT_FORM_SUCCESS, payload: response?.data?.data });
    } else if (response.status > 200 && response.status < 500) {
      yield put({ type: SUBMIT_FORM_ERROR, payload: { error: response?.data?.error_message || commonError } });
    } else {
      yield put({ type: SUBMIT_FORM_ERROR, payload: { error: commonError } });
    }
  } catch (err) {
    yield put({ type: SUBMIT_FORM_ERROR, payload: { error: commonError } });
  }
}

export default function* GoldWaitlistSagas() {
  yield takeLatest(GET_LEAD_DETAILS, getLeadDetails);
  yield takeLatest(SUBMIT_FORM, submitForm);
}
