import { call, put, delay, select, takeLatest } from 'redux-saga/effects';
import {
  GET_DECRYPTED_DATA,
  GET_DECRYPTED_DATA_SUCCESS,
  INITIATE_PAYMENT,
  INITIATE_PAYMENT_SUCCESS,
  INITIATE_PAYMENT_FAILURE,
  GET_UPI_COLLECT_PAYMENT_STATUS,
  GET_UPI_COLLECT_PAYMENT_STATUS_PENDING,
  GET_UPI_COLLECT_PAYMENT_STATUS_SUCCESS,
  GENERATE_EXTERNAL_PAYMENT_LINK,
  GENERATE_EXTERNAL_PAYMENT_LINK_SUCCESS,
} from './actionTypes';
import {
  decryptCollectLinkApiCall,
  getUpiCollectApiCall,
  getPaymentStatusApiCall,
  generatePaymentLinkApiCall,
} from './services';

const commonError = 'Something went wrong, please try again later';
const stateValue = (state) => state.UpiCollectReducer;

function* getDecryptedData({ payload }) {
  try {
    const response = yield call(decryptCollectLinkApiCall, payload);
    if (response.status === 200 && response?.data?.status) {
      yield put({ type: GET_DECRYPTED_DATA_SUCCESS, payload: response?.data?.results });
    }
  } catch (err) {
    // Do nothing
  }
}

function* initiatePayment({ payload }) {
  try {
    const {
      decryptedData: { token, device_id },
    } = yield select(stateValue);
    const response = yield call(getUpiCollectApiCall, { auth_token: token, device_id, payload });
    if (response.status === 200 && response?.data?.success) {
      yield put({ type: INITIATE_PAYMENT_SUCCESS, payload: response?.data?.data });
    } else if (response.status > 200 && response.status < 500) {
      yield put({ type: INITIATE_PAYMENT_FAILURE, payload: { error: response?.data?.error_message || commonError } });
    } else {
      yield put({ type: INITIATE_PAYMENT_FAILURE, payload: { error: commonError } });
    }
  } catch (err) {
    yield put({ type: INITIATE_PAYMENT_FAILURE, payload: { error: commonError } });
  }
}

function* getPaymentStatus({ payload }) {
  let count = 0;
  while (true) {
    try {
      const {
        decryptedData: { token, device_id },
      } = yield select(stateValue);
      const response = yield call(getPaymentStatusApiCall, {
        auth_token: token,
        device_id,
        transactionId: payload?.txnId,
      });
      if (response.status === 200 && response?.data?.success) {
        if (response?.data?.data?.status === 'SUCCESS' || response?.data?.data?.status === 'FAILED') {
          yield put({ type: GET_UPI_COLLECT_PAYMENT_STATUS_SUCCESS, payload: response?.data?.data });
          break;
        } else {
          yield put({ type: GET_UPI_COLLECT_PAYMENT_STATUS_PENDING, payload: response?.data?.data });
        }
      }
      count += 1;
      if (count >= 10) break;
    } catch (err) {
      // Do nothing
    }
    yield delay(30000);
  }
}

function* generateExternalPaymentLink({ payload }) {
  try {
    const {
      decryptedData: { token, device_id },
    } = yield select(stateValue);
    const response = yield call(generatePaymentLinkApiCall, { auth_token: token, device_id, payload });
    yield put({ type: GENERATE_EXTERNAL_PAYMENT_LINK_SUCCESS, payload: response?.data?.status });
    if (response.status === 200 && response?.data?.status) {
      window.location.href = response?.data?.results;
    }
  } catch (err) {
    // Do nothing
  }
}

export default function* GoldWaitlistSagas() {
  yield takeLatest(GET_DECRYPTED_DATA, getDecryptedData);
  yield takeLatest(INITIATE_PAYMENT, initiatePayment);
  yield takeLatest(GET_UPI_COLLECT_PAYMENT_STATUS, getPaymentStatus);
  yield takeLatest(GENERATE_EXTERNAL_PAYMENT_LINK, generateExternalPaymentLink);
}
