import React, { Suspense, lazy } from "react";
import { Routes, Route } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "react-query";

import Home from '../Home';
import Header from '../../components/sharedComponents/header';
import Footer from '../../components/sharedComponents/Footer';
import MetaAndTitle from '../../components/sharedComponents/MetaAndTitle';
import { CITY_WISE_INSTANT_PERSONAL_LOANS } from '../../utils/constant';
import HomeNew from '../HomeNew/HomeNew';
// import RequestOtp from '../InsuranceJourney/requestOtp';
// import VerifyOtp from '../InsuranceJourney/verifyOtp';
// import Paynow from '../InsuranceJourney/paynow';
// import GenerateNach from '../CLIJourney/generateNach';
// import CreateTicket from '../CreateTicket';
// import Insurance from '../Insurance';
import ElevateDisable from '../ElevateDisable/ElevateDisable';
import Yubi from '../Wealth/components/Yubi';

const RPD = lazy(() => import('../RPD'));
const RPDStatus = lazy(() => import('../RPD/rpdStatus'));
const PostList = lazy(() => import('../PostsList'));
const SignUp = lazy(() => import('../signUp'));
const LinkSent = lazy(() => import('../linkSent'));
const AboutUs = lazy(() => import('../aboutUs'));
const Insurance = lazy(() => import('../../pages/Insurance/index'));
const WhyUs = lazy(() => import('../WhyUs'));
const WhyUsNew = lazy(() => import('../WhyUsNew'));
const Rewards = lazy(() => import('../Rewards'));
const Careers = lazy(() => import('../Careers'));
const Faq = lazy(() => import('../faq'));
// import ContactUs from '../contactUs';
const OtpPage = lazy(() => import('../otp'));
const Partners = lazy(() => import('../Partners'));
const BasiDetail = lazy(() => import('../basicDetails'));
const LoginPage = lazy(() => import('../login'));
const CustomerServices = lazy(() => import('../CustomerServices'));
const GrievanceRedressal = lazy(() => import('../GrievanceRedressal'));
const TermsAndConditions = lazy(() => import('../TermsAndConditions'));
const TermsAndConditionsAmazon = lazy(() => import('../TermsAndConditionsAmazon'));
const WomenCard = lazy(() => import('../WomenCard'));
// const DealsAndOffers = lazy(()=> import ('../DealsAndOffers'));
const Unsubscribe = lazy(() => import('../unsubscribe'));
const PersonalLoanEmiCalc = lazy(() => import('../PersonalLoanEmiCalc'));
const CareerNew = lazy(() => import('./CareersNew'));
const Feedback = lazy(() => import('../feedback'));
const ValidateFeedback = lazy(() => import('../feedback/alternateFeedback'));
const ArmyPage = lazy(() => import('../Army'));
const OurFounders = lazy(() => import('../Founders'));
const CustomerServiceListSecond = lazy(() => import('../CustomerServices/CustomerServiceListSecond'));
const CustomerServiceListThird = lazy(() => import('../CustomerServices/CustomerServiceListThird'));
const NewsRoom = lazy(() => import('../NewsRoom'));
const AppDownload = lazy(() => import('../appDownload'));
const DealsAndOffersDetails = lazy(() => import('../commonDetails'));
const PrivacyPolicyAkara = lazy(() => import('../PrivacyPolicyAkara'));
const PrivacyPolicyEQX = lazy(() => import('../PrivacyPolicyEQX'));
const PrivacyPolicy = lazy(() => import('../PrivacyPolicy'));
const Banking = lazy(() => import('../banking'));
const BankMode = lazy(() => import('../banking/bankMode'));
const BankStatementUpload = lazy(() => import('../banking/bankStatementUpload'));
const BankSuccess = lazy(() => import('../banking/bankSuccess'));
const MissingRoute = lazy(() => import('../../components/MissingRoute'));
const Enach = lazy(() => import('../enach'));
const StashfinRecoveryAgency = lazy(() => import('../recoveryAgency'));
const ReferralProgram = lazy(() => import('../stahcashRefferalProgram'));
const Sitemap = lazy(() => import('../sitemap'));
const UPIMandate = lazy(() => import('../upiMandate'));
const InstantPersonalLoans = lazy(() => import('../InstantPersonalLoans'));
const UpiMandateStatus = lazy(() => import('../upiMandate/upiMandateStatus'));
const Esign = lazy(() => import('../esign'));
const NachOptions = lazy(() => import('../NachOptions'));
const CityWiseInstantPersonalLoans = lazy(() => import('../CityWiseInstantPersonalLoans'));
const IrctcOffer = lazy(() => import('../offers/irctcOffer'));
const IPL = lazy(() => import('../Ipl'));
const AdditionalTicketPopup = lazy(() => import('../CustomerServices/additionalTicketPopup'));
const AccountAggregator = lazy(() => import('../AccountAggregator'));
const InitiateError = lazy(() => import('../AccountAggregator/initiateError'));
const ConsentApprovalPage = lazy(() => import('../AccountAggregator/consentApproval'));
const ConsentStatus = lazy(() => import('../Congratulations'));
const PTPCampaign = lazy(() => import('../PTPCampaign'));
const PTPReason = lazy(() => import('../PTPCampaign/ptpReason'));
const DeepLink = lazy(() => import('../deeplink'));
const StashCash = lazy(() => import('../Stashcash'));
const SentinelOffer = lazy(() => import('../SentinelOffer'));
const InsuranceLandingPage = lazy(() => import('../InsuranceJourney'));
const RequestOtp = lazy(() => import('../InsuranceJourney/requestOtp'));
const VerifyOtp = lazy(() => import('../InsuranceJourney/verifyOtp'));
const Paynow = lazy(() => import('../InsuranceJourney/paynow'));
const InsuranceStashcash = lazy(() => import('../InsuranceJourney/insuranceStashcash'));
const InsuranceStashcashListing = lazy(() => import('../InsuranceJourney/insuranceStashcashListing'));
const InsuranceStashcashLead = lazy(() => import('../InsuranceJourney/insuranceStashcashLead'));
const InsuranceStashcashCongrats = lazy(() => import('../InsuranceJourney/insuranceStashcashCongartulations'));
const PaymentStatus = lazy(() => import('../InsuranceJourney/paymentStatus'));
const CLIJourney = lazy(() => import('../CLIJourney'));
const CreateTicket = lazy(() => import('../CreateTicket'));
const LoanRestructure = lazy(() => import('../LoanRestructure'));
const FreedomJourneyDocUpload = lazy(() => import('../freedomJourney'));
const GoldWaitlist = lazy(() => import('../GoldWaitlist'));
const WealthWaitlist = lazy(() => import('../../pages/Wealth_waitlist'));
const Irctc = lazy(() => import('../../pages/IRCTC'));
const OKYC = lazy(() => import('../KYC/Okyc_v2'));
const REKYC = lazy(() => import('../KYC'));
const REKYCMessage = lazy(() => import('../KYC/KycMessage'));
const Wealth = lazy(() => import('../Wealth'));
const UpiCollect = lazy(() => import('../UpiCollect'));
const WebsiteDisclosure = lazy(() => import('../WebsiteDisclosure'));
const HealthInsurance = lazy(() => import('../InsuranceJourney/Components/healthInsurance/HealthInsurance'));
const JobLoss = lazy(() => import('../InsuranceJourney/Components/insuranceJobLoss/jobLoss'));
const ReferAndEarn = lazy(() => import('../ReferAndEarn'));
// const LeaderBoard = lazy(() => import('../../pages/InsuranceJourney/Components/'));
const WealthThankyou = lazy(() => import(`../../pages/Wealth/components/ThankyouPage`));
const SmallLoans = lazy(() => import("../SmallLoans"));
const PrivateLoans = lazy(() => import("../PrivateLoans"));
const UnsecuredLoans = lazy(() => import("../UnsecuredLoans"));

const DealsAndOffersNew = lazy(() => import('../DealsAndOffersNew'));
const StashPlus = lazy(() => import('../InsuranceJourney/Components/stashplus/stashplus'));
const Ivr = lazy(() => import('../Ivr'));
const DeleteAccount = lazy(() => import('../DeleteAccount'));
const GameEngine = lazy(() => import('../GameEngine'));
// const ElevateDisable = lazy(()=>{import('../ElevateDisable/ElevateDisable')})

const AboutUsEqx = lazy(() => import("../AboutUsEqx"));
const FaqsEqx = lazy(() => import("../FaqsEqx"));
const UpiLiteTncEqx = lazy(() => import("../UpiLiteTncEqx"));
const PrivacyPolicyNewEqx = lazy(() => import("../PrivacyPolicyNewEqx"));
const TermsOfUseEqx = lazy(() => import("../TermsOfUseEqx"));

const client = new QueryClient();

const OpenRoutes = () => {
  const suspenseFallback = () => <div style={{ display: "flex", justifyContent: "center" }}>Loading...</div>;

  return (
    <>
      <MetaAndTitle />
      <Header />
      <Suspense fallback={suspenseFallback()}>
        <QueryClientProvider client={client}>
          <Routes>
            <Route path="/list" element={<PostList />} />
            <Route path="/linksent" element={<LinkSent />} />
            <Route path="/" element={<HomeNew />} />
            <Route path="/home" element={<Home />} />
            <Route path="/otppage" element={<OtpPage />} />
            <Route path="/signup" element={<SignUp />} />
            <Route path="/about-us" element={<AboutUs />} />
            <Route path="/insurance" element={<Insurance />} />
            <Route path="/rewards" element={<Rewards />} />
            <Route path="/why-us-old" element={<WhyUs />} />
            <Route path="/why-us" element={<WhyUsNew />} />
            <Route path="/careers-old" element={<Careers />} />
            <Route path="/linksent" element={<LinkSent />} />
            <Route path="/faq" element={<Faq />} />
            <Route path="/partners" element={<Partners />} />
            <Route path="/application" element={<BasiDetail />} />
            <Route path="/app-download" element={<AppDownload />} />
            <Route path="/common-details" element={<DealsAndOffersDetails />} />
            <Route path="/login" element={<LoginPage />} />
            <Route path="/customer-services" element={<CustomerServices />} />
            <Route path="/grievance-redressal-mechanism" element={<GrievanceRedressal />} />
            <Route path="/terms-and-conditions" element={<TermsAndConditions />} />
            <Route path="/terms-and-conditions-amazon" element={<TermsAndConditionsAmazon />} />
            <Route path="/privacy-policy-akara" element={<PrivacyPolicyAkara />} />
            <Route path="/privacy-policy" element={<PrivacyPolicyEQX />} />
            <Route path="/privacy-policies" element={<PrivacyPolicy />} />
            <Route path="/women-card" element={<WomenCard />} />
            <Route path="/unsubscribe-communication" element={<Unsubscribe />} />
            <Route path="/loan-calculator" element={<PersonalLoanEmiCalc />} />
            <Route path="/careers" element={<CareerNew />} />
            <Route path="/founders" element={<OurFounders />} />
            <Route path="/feedback/save" element={<Feedback />} />
            <Route path="/feedback/reopen" element={<Feedback />} />
            <Route path="/feedback/validate" element={<ValidateFeedback />} />
            <Route path="/sentinel" element={<ArmyPage />} />
            <Route path="/customer-service/:id" element={<CustomerServiceListSecond />} />
            <Route path="/customer-services/:articalId" element={<CustomerServiceListThird />} />
            <Route path="/newsroom" element={<NewsRoom />} />
            <Route path="/refer-and-earn" element={<ReferralProgram />} />
            <Route path="/sitemap" element={<Sitemap />} />
            <Route path="/instant-personal-loans" element={<InstantPersonalLoans />} />

            {/* banking screens start */}
            <Route path="/banking-statement" element={<Banking />} />
            <Route path="/BankMode" element={<BankMode />} />
            <Route path="/BankUpload" element={<BankStatementUpload />} />
            <Route path="/BankSuccess" element={<BankSuccess />} />
            {/* banking screens end */}
            <Route path="/enach" element={<Enach />} />
            <Route path="/recovery-agency" element={<StashfinRecoveryAgency />} />
            <Route path="/upi-mandate" element={<UPIMandate />} />
            <Route path="/upi-mandate-status" element={<UpiMandateStatus />} />
            <Route path="/e-sign" element={<Esign />} />
            <Route path="/nach-options" element={<NachOptions />} />
            <Route path="/bank-details" element={<RPD />} />
            <Route path="/bank-details-status" element={<RPDStatus />} />
            <Route path={`${CITY_WISE_INSTANT_PERSONAL_LOANS}/:city`} element={<CityWiseInstantPersonalLoans />} />
            <Route path="/irctc_offer" element={<IrctcOffer />} />
            <Route path="/ipl" element={<IPL />} />
            <Route path="/ticket-form" element={<AdditionalTicketPopup />} />
            <Route path="/select-bank" element={<AccountAggregator />} />
            <Route path="/initiate-error" element={<InitiateError />} />
            <Route path="/consent-approval" element={<ConsentApprovalPage />} />
            <Route path="/consent-status" element={<ConsentStatus />} />
            <Route path="/ptp-date/:duration" element={<PTPCampaign />} />
            <Route path="/ptp-date/reason" element={<PTPReason />} />
            <Route path="/app" element={<DeepLink />} />
            <Route path="/stashcash" element={<StashCash />} />
            <Route path="/sentinel_offer" element={<SentinelOffer />} />
            <Route path="/insurance/:productId" element={<InsuranceLandingPage />} />
            <Route path="/insurance/request-otp" element={<RequestOtp />} />
            <Route path="/insurance/verify-otp" element={<VerifyOtp />} />
            <Route path="/insurance/paynow/:productId" element={<Paynow />} />
            <Route path="insurance/payment-status" element={<PaymentStatus />} />
            <Route path="/insurance-stashcash" element={<InsuranceStashcash />} />
            <Route path="/insurance/pocket-insurance" element={<InsuranceStashcashListing />} />
            <Route path="/insurance-stashcash-lead" element={<InsuranceStashcashLead />} />
            <Route path="/insurance-stashcash-greet" element={<InsuranceStashcashCongrats />} />
            <Route path="/insurance/health" element={<HealthInsurance />} />
            <Route path="/insurance/jobloss" element={<JobLoss />} />
            <Route path="/insurance/stashplus" element={<StashPlus />} />

            {/* <Route path="/insurance/leaderboard" element={<LeaderBoard />} /> */}
            <Route path="/cli" element={<CLIJourney />} />
            <Route path="/create-ticket" element={<CreateTicket />} />
            <Route path="/loan-restructure" element={<LoanRestructure />} />
            <Route path="/document-upload" element={<FreedomJourneyDocUpload />} />
            <Route path="/okyc-v2-rekyc" element={<OKYC />} />
            <Route path="/rekyc-details-verification" element={<REKYC />} />
            <Route path="/rekyc-verification-result" element={<REKYCMessage />} />
            <Route path="/gold-waitlist" element={<GoldWaitlist />} />
            <Route path="/wealth-waitlist" element={<WealthWaitlist />} />
            <Route path="/wealth/:screen" element={<Wealth />} />
            <Route path="/growth/wealth/callback" element={<WealthThankyou />} />
            <Route path="/upi-collect" element={<UpiCollect />} />
            <Route path="/website-disclosure" element={<WebsiteDisclosure />} />
            <Route path="/deals-and-offers/:screen" element={<DealsAndOffersNew />} />
            <Route path="/refer-and-earn/play-and-win" element={<ReferAndEarn />} />
            <Route path="/ivr" element={<Ivr />} />
            <Route path="/delete-account" element={<DeleteAccount />} />
            <Route path="/rewards/:screen" element={<GameEngine />} />
            <Route path="/elevate-disable" element={<ElevateDisable />} />
            <Route path="/yubi" element={<Yubi />} />
            <Route path="/small-loans" element={<SmallLoans />} />
            <Route path="/private-loans" element={<PrivateLoans />} />
            <Route path="/unsecured-loans" element={<UnsecuredLoans />} />

            <Route path="/about-us-eqx" element={<AboutUsEqx />} />
            <Route path="/faqs-eqx" element={<FaqsEqx />} />
            <Route path="/upi-lite-tnc-eqx" element={<UpiLiteTncEqx />} />
            <Route path="/privacy-policy-eqx" element={<PrivacyPolicyNewEqx />} />
            <Route path="/terms-of-use-eqx" element={<TermsOfUseEqx />} />

            <Route path="*" element={<MissingRoute />} />

            {/* IRCTC static page */}
            <Route path="/irctc" element={<Irctc />} />
          </Routes>
        </QueryClientProvider>
      </Suspense>
      <Footer />
    </>
  );
};

export default OpenRoutes;
