import { get, getStasheasy, postStasheasy } from "../../../service";
import { GET_CUSTOMER_SERVICE_PARAM, POST_CUSTOMER_SERVICE_SUBMIT_FORM_PARAM } from "../../../utils/constant";
import { ErrorMessage } from "../../../utils/responseMessage";

export const customerServiceApiCall = async () => {
    try {
        const response = await getStasheasy(GET_CUSTOMER_SERVICE_PARAM);
        if (response.status === true) {
          return response;
        }
        if (response.status === false) {
          return response;
        }
        return response;
      } catch (e) {
        ErrorMessage({
          message: e,
        });
        return false;
      }
    };
    //create ticket api service
    export const createTicketSubmitApiCall = async (data) => {
        try {
          const req = {
            api: process.env.REACT_APP_ADMIN_PANEL_API,
            body: data,
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
          };
              
          const response = await postStasheasy(POST_CUSTOMER_SERVICE_SUBMIT_FORM_PARAM, req.body);
          if (response.status === true) {
            return response;
          }
          if (response.status === false) {
            return response;
          }
          return response;
        } catch (e) {
          ErrorMessage({
            message: e,
          });
          return false;
        }
      };