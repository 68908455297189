import { call, put, takeLatest } from "redux-saga/effects";

import {
  SAVE_COMMENT,
  SEND_OTP_ACTION,
  SEND_OTP_ERROR,
  SEND_OTP_SUCCESS,
  VARIFY_OTP_ACTION,
  VARIFY_OTP_ERROR,
  VARIFY_OTP_SUCCESS,
} from '../../../utils/constant';
import { saveComment, sendOTPFunc, varifyOTPFunc } from '../services';
import { saveCommentSuccess, saveCommentFail } from '../actions';

  function* onCommentSave(data) {
    try{
      let response = yield call(saveComment, data.payload);
      if(response?.status === true){
        yield put(saveCommentSuccess(response?.message));
      } else if(response?.status === false){
        yield put(saveCommentFail(response?.errors));
      }
    }catch(err){
      yield put(saveCommentFail(err))
    }
  }

function* onSendOTP(data) {
  try {
    const response = yield call(sendOTPFunc, data.payload);
    if (response?.status === 200) {
      yield put({ type: SEND_OTP_SUCCESS, payload: response.data });
    } else if (response?.status !== 200) {
      yield put({ type: SEND_OTP_ERROR, payload: response.data });
    }
  } catch (err) {
    yield put({
      type: SEND_OTP_ERROR,
      payload: { status: false, errors: 'Something went wrong, please try again later' },
    });
  }
}

function* onVarifyOTP(data) {
  try {
    const response = yield call(varifyOTPFunc, data.payload);
    console.log({ response });
    if (response?.status === 200) {
      yield put({ type: VARIFY_OTP_SUCCESS, payload: response.data.status });
    } else if (response?.status !== 200) {
      yield put({ type: VARIFY_OTP_ERROR, payload: response.data });
    }
  } catch (err) {
    yield put({
      type: VARIFY_OTP_ERROR,
      payload: { status: false, errors: 'Something went wrong, please try again later' },
    });
  }
}

export default function* feedbackSagas() {
  yield takeLatest(SAVE_COMMENT, onCommentSave);
  yield takeLatest(SEND_OTP_ACTION, onSendOTP);
  yield takeLatest(VARIFY_OTP_ACTION, onVarifyOTP);
}
