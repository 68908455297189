import { call, put, takeLatest, select } from "redux-saga/effects";
import { DEAL_AND_OFFER } from "../../../utils/constant";
import {dealsAndOfferApiCall } from "../services";
import {
  dealaAndOffer,
  dealaAndOfferSuccess,
  dealaAndOfferFail,
} from "../actions";

function* onDealsAndOfferPosts() {
  try {
    let dealaAndOfferData = yield call(dealsAndOfferApiCall);
    console.log('dealaAndOfferData: ', dealaAndOfferData);
    if (dealaAndOfferData?.status === true) {
      yield put(dealaAndOfferSuccess(dealaAndOfferData));
    } else if (dealaAndOfferData?.status === false) {
      yield put(dealaAndOfferFail(dealaAndOfferData?.errors));
    }
  } catch (error) {
    yield put(dealaAndOfferFail(error));
  }
}

export default function* onDealsAndOfferPostssagas() {
yield takeLatest(DEAL_AND_OFFER, onDealsAndOfferPosts);
}
