import axios from 'axios';

const BASE_URL = process.env.REACT_APP_BASE_API_URL;

export const decryptCollectLinkApiCall = async ({ encrypted_data, signature }) => {
  try {
    const response = await axios.get(
      `${BASE_URL}payment/links/decrypt-collect-link?encrypted_data=${encrypted_data}&signature=${signature}`,
    );
    return response;
  } catch (err) {
    return err.response;
  }
};

export const getUpiCollectApiCall = async ({ auth_token, device_id, payload }) => {
  try {
    const response = await axios.post(`${BASE_URL}payments/v1/get-upi-collect`, payload, {
      headers: { auth_token, device_id, 'Content-Type': 'application/json' },
    });
    return response;
  } catch (err) {
    return err.response;
  }
};

export const getPaymentStatusApiCall = async ({ auth_token, device_id, transactionId }) => {
  try {
    const response = await axios.get(`${BASE_URL}payments/v1/get-payment-status/${transactionId}`, {
      headers: { auth_token, device_id },
    });
    return response;
  } catch (err) {
    return err.response;
  }
};

export const generatePaymentLinkApiCall = async ({ auth_token, device_id, payload }) => {
  try {
    const response = await axios.post(`${BASE_URL}payment/links/generate-payment-link`, payload, {
      headers: { auth_token, device_id, 'Content-Type': 'application/json' },
    });
    return response;
  } catch (err) {
    return err.response;
  }
};
