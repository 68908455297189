/* eslint-disable max-len */
/* eslint-disable max-statements */
/* eslint-disable complexity */

import {
  SET_CURRENT_SCREEN,
  GET_DEALS_AND_OFFERS_LIST,
  GET_DEALS_AND_OFFERS_LIST_SUCCESS,
  GET_DEALS_AND_OFFERS_LIST_FAILURE,
  GET_VOUCHER_DETAILS,
  GET_VOUCHER_DETAILS_SUCCESS,
  GET_VOUCHER_DETAILS_FAILURE,
  GET_CART_DETAILS,
  GET_CART_DETAILS_SUCCESS,
  GET_CART_DETAILS_FAILURE,
  UPDATE_CART,
  UPDATE_CART_SUCCESS,
  UPDATE_CART_FAILURE,
  GET_PAYMENT_LINK_FOR_DEALS,
  // GET_PAYMENT_LINK_FOR_DEALS_SUCCESS,
  // GET_PAYMENT_LINK_FOR_DEALS_FAILURE,
  GET_PAYMENT_DETAILS,
  GET_PAYMENT_DETAILS_SUCCESS,
  GET_PAYMENT_DETAILS_FAILURE,
  GET_ORDER_DETAILS,
  GET_ORDER_DETAILS_SUCCESS,
  GET_ORDER_DETAILS_FAILURE,
  GET_ORDERS_LIST,
  GET_ORDERS_LIST_SUCCESS,
  GET_ORDERS_LIST_FAILURE,
  SET_ORDER_DETAILS,
  LOC_DISCOUNT_FAILURE,
  SET_PAYMENT_STATUS_API_POLLING,
  SET_IS_CART_ALERT_OPEN,
  CHECK_DISCOUNT_ON_CART_ITEM,
  CHECK_OKYC_SUCCESS,
  CHECK_OKYC_FAILURE,
} from './actionTypes';

const initialState = {
  mainLoader: false,
  currentScreen: '',
  selectedVoucherUid: null,
  selectedVoucherDetails: {},
  errorOnSelectingVoucher: false,
  loaderOnUpdatingCart: false,
  cartData: {},
  dealsAndOffersList: [],
  errorInGettingDealsAndOffersList: false,
  paymentDetails: [],
  errorInGettingPaymentDetails: false,
  orderUidForPaymentStatus: null,
  selectedOrderUidForDetail: null,
  couponDetails: {},
  errorInUpdatingCartOnVoucherDetailsPage: false,
  cartDetails: {},
  errorInGettingCartDetails: false,
  ordersList: [],
  errorInGettingOrdersList: false,
  errorInGettingOrderDetails: false,
  paymentSuccessfullyCompleted: false,
  selectedOrderDetails: {},
  paymentInitiated: false,
  paymentLinkDetails: {},
  errorInGettingPaymentLink: false,
  locDiscount: {},
  errorInLocDiscount: false,
  paymentStatusApiPolling: false,
  isCartAlertOpen: false,
  cartAlertError: '',
  paymentDone: false,
};

const DealsAndOffersNewReducer = (state = initialState, action = {}) => {
  const { type, payload } = action;

  switch (type) {
    case SET_CURRENT_SCREEN:
      return {
        ...state,
        currentScreen: payload,
      };

    case GET_VOUCHER_DETAILS:
      return {
        ...state,
        mainLoader: true,
        selectedVoucherUid: payload?.uid,
      };

    case GET_VOUCHER_DETAILS_SUCCESS:
      return {
        ...state,
        mainLoader: false,
        selectedVoucherDetails: payload,
      };

    case GET_VOUCHER_DETAILS_FAILURE:
      return {
        ...state,
        mainLoader: false,
        errorOnSelectingVoucher: true,
      };

    // case GET_PAYMENT_LINK_FOR_DEALS_SUCCESS:
    //   return {
    //     ...state,
    //     cartData: {},
    //     paymentLinkDetails: payload,
    //   };

    case CHECK_OKYC_SUCCESS:
      return {
        ...state,
        cartData: {},
        paymentLinkDetails: payload,
      };

    case UPDATE_CART:
      return {
        ...state,
        loaderOnUpdatingCart: true,
      };

    case UPDATE_CART_SUCCESS:
      return {
        ...state,
        loaderOnUpdatingCart: false,
        cartData: payload,
      };

    case UPDATE_CART_FAILURE:
      return {
        ...state,
        loaderOnUpdatingCart: false,
        errorInUpdatingCartOnVoucherDetailsPage: true,
      };

    case GET_CART_DETAILS: {
      return {
        ...state,
        mainLoader: true,
      };
    }

    case GET_CART_DETAILS_SUCCESS: {
      const data = {};
      payload?.items?.forEach((item) => {
        data[item.uid] = item;
      });
      return {
        ...state,
        mainLoader: false,
        cartDetails: payload,
        cartData: data,
      };
    }

    case GET_CART_DETAILS_FAILURE:
      return {
        ...state,
        mainLoader: false,
        errorInGettingCartDetails: true,
      };

    case GET_DEALS_AND_OFFERS_LIST:
      return {
        ...state,
        mainLoader: true,
      };

    case GET_DEALS_AND_OFFERS_LIST_SUCCESS:
      return {
        ...state,
        mainLoader: false,
        dealsAndOffersList: payload,
      };

    case GET_DEALS_AND_OFFERS_LIST_FAILURE:
      return {
        ...state,
        mainLoader: false,
        errorInGettingDealsAndOffersList: true,
      };

    case GET_PAYMENT_DETAILS:
      return {
        ...state,
        orderUidForPaymentStatus: payload?.order_uid,
        paymentStatusApiPolling: true,
      };

    case GET_PAYMENT_DETAILS_SUCCESS:
      return {
        ...state,
        paymentDone: true,
        paymentDetails: payload,
        paymentSuccessfullyCompleted: [2, 3].includes(payload?.status),
      };

    case GET_PAYMENT_DETAILS_FAILURE:
      return {
        ...state,
        errorInGettingPaymentDetails: true,
      };

    case GET_ORDERS_LIST:
      return {
        ...state,
        mainLoader: true,
      };

    case GET_ORDERS_LIST_SUCCESS:
      return {
        ...state,
        mainLoader: false,
        ordersList: payload,
      };

    case GET_ORDERS_LIST_FAILURE:
      return {
        ...state,
        mainLoader: false,
        errorInGettingOrdersList: true,
      };

    case GET_ORDER_DETAILS:
      return {
        ...state,
        mainLoader: true,
        selectedOrderUidForDetail: payload?.order_uid,
      };

    case GET_ORDER_DETAILS_SUCCESS:
      return {
        ...state,
        mainLoader: false,
        couponDetails: payload,
      };

    case GET_ORDER_DETAILS_FAILURE:
      return {
        ...state,
        mainLoader: false,
        errorInGettingOrderDetails: true,
      };

    case SET_ORDER_DETAILS:
      return {
        ...state,
        selectedOrderDetails: payload,
      };

    case GET_PAYMENT_LINK_FOR_DEALS:
      return {
        ...state,
        mainLoader: true,
        paymentInitiated: true,
      };

    // case GET_PAYMENT_LINK_FOR_DEALS_FAILURE:
    //   return {
    //     ...state,
    //     mainLoader: false,
    //     errorInGettingPaymentLink: true,
    //     isCartAlertOpen: payload?.cartAlert,
    //     cartAlertError: payload?.error,
    //   };

    case CHECK_OKYC_FAILURE:
      return {
        ...state,
        mainLoader: false,
        errorInGettingPaymentLink: true,
        isCartAlertOpen: true,
        cartAlertError: payload?.error,
      };

    case LOC_DISCOUNT_FAILURE:
      return {
        ...state,
        mainLoader: false,
        errorInLocDiscount: true,
      };

    case SET_PAYMENT_STATUS_API_POLLING:
      return {
        ...state,
        paymentStatusApiPolling: false,
      };

    case SET_IS_CART_ALERT_OPEN:
      return {
        ...state,
        isCartAlertOpen: payload,
      };

    case CHECK_DISCOUNT_ON_CART_ITEM:
      return {
        ...state,
        cartData: {
          ...state.cartData,
          [payload.uid]: {
            ...state.cartData[payload.uid],
            selectBestOfferData: {
              ...state.cartData[payload.uid].selectBestOfferData,
              checked: payload.event.target.checked,
            },
          },
        },
      };

    default:
      return state;
  }
};

export default DealsAndOffersNewReducer;
