import axios from 'axios';

const BASE_URL = process.env.REACT_APP_BASE_API_URL;

export const referAndEarnApiCall = async ({ token, deviceId, payload }) => {
  try {
    const response = await axios.post(`${BASE_URL}v2/api/`, payload, {
      headers: {
        auth_token: token,
        device_id: deviceId,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    });
    return response;
  } catch (error) {
    return error;
  }
};
