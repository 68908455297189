import axios from 'axios';

const BASE_URL = process.env.REACT_APP_BASE_API_URL;

export const leadDetailApiCall = async ({ token, deviceId }) => {
  try {
    const res = await axios.get(`${BASE_URL}magneto/lead/detail?type=2`, {
      headers: { auth_token: token, device_id: deviceId },
    });
    return res;
  } catch (err) {
    return err.response;
  }
};

export const leadSubmitApiCall = async ({ token, deviceId, utmData, payload }) => {
  try {
    const { utm_source = null, utm_medium = null, utm_campaign = null } = utmData;
    const utmValues = [
      utm_source && `utm_source=${utm_source}`,
      utm_medium && `utm_medium=${utm_medium}`,
      utm_campaign && `utm_campaign=${utm_campaign}`,
    ].filter(Boolean);
    const utmString = utmValues.join('&');
    const res = await axios.post(`${BASE_URL}magneto/lead/submit?${utmString}`, payload, {
      headers: { auth_token: token, device_id: deviceId },
    });
    return res;
  } catch (err) {
    return err.response;
  }
};
