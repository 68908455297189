import React from "react";
import qrcode from "../../../assets/images/stashfin_app_barcode.png";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MenuIcon from "@mui/icons-material/Menu";
import appstore from "../../../assets/images/appstore.png";
import playstore from "../../../assets/images/playstore.png";

  
import {
  LOAN_CALCULATOR,
  LOGIN_MAKE_PAYMENT,
  ARMY_LINK,
  CUSTOMER_SERVICE,
  ABOUT_US,
  FOUNDER_LINK,
  NEWS_ROOM_LINK,
  REFERRAL_PROGRAM,
  CHECK_CIBIL_REPORT,
  INSURANCE,
} from '../../../utils/constant';

const HeaderLinks = (props) => {
  const { anchorEl, setAnchorEl } = props;
  const handleClick = (event) => {
    if (anchorEl !== event.currentTarget) {
      setAnchorEl(event.currentTarget);
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const onClickMenuLinks = (redirectTo, openIn) => {
    setAnchorEl(null);
    window.open(redirectTo, openIn);
  };

  // const onClickGetYourCard = () => {
  //   window.open('/signup', '_self');
  // };

  return (
    <>
      <div className="col-xl-6 col-lg-7 col-md-6 hdrMenu d-none d-lg-block">
        <div className="navigation">
          <ul>
            <li>
              <a href="/signup">Apply Now</a>
            </li>
            <li>
              <a href="/login">Make a Payment</a>
            </li>
            <li>
              <a href={LOAN_CALCULATOR}>Calculate EMI</a>
            </li>
            <li>
              <a href="/about-us">About Us</a>
            </li>
            <li>
              <a href="/founders">Meet Our Founders</a>
            </li>
            <li>
              <a href="/insurance">Insurance</a>
            </li>
          </ul>
        </div>
      </div>
      <div className="col-xl-4 col-lg-3 col-md-6 col-sm-6 col-4 flex-row alignCenter hdrRight">
        <div className="headerBtn">
          <div className="dropdown">
            <button
              className="getStart"
              type="button"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              Get Stashfin app
              <div className="qrDropDown">
                <div className="imageCode">
                  <img src={qrcode} alt="" />
                </div>
                <div className="QR_text">
                  <p>
                    Scan QR to download the app from
                  </p>
                  <div className="imageIcon">
                    <div className="icon_name_div">
                      <img className="store_icon" src={appstore}></img>
                      <span className="store_name">Appstore</span>
                    </div>
                    <div className="icon_name_div">
                      <img className="store_icon" src={playstore}></img>
                      <span className="store_name">Playstore</span>
                    </div>
                  </div>
                </div>
              </div>
            </button>
          </div>
        </div>
        <div
          className="dotsIcon mobile d-block d-lg-none"
          id="dropdown-menu"
          aria-controls={Boolean(anchorEl) ? "dropdown-basic-menu" : undefined}
          aria-haspopup="true"
          onClick={(e) => handleClick(e)}
        >
          <MenuIcon />
        </div>
        <div
          className="dotsIcon d-none d-lg-block"
          id="dropdown-menu"
          aria-controls={Boolean(anchorEl) ? "dropdown-basic-menu" : undefined}
          aria-haspopup="true"
          onMouseOver={(e) => handleClick(e)}
        >
          <MenuIcon />
        </div>
        <Menu
          disableScrollLock={true}
          anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
          transformOrigin={{ vertical: "top", horizontal: "right" }}
          id="dropdown-basic-menu"
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleClose}
          MenuListProps={{ onMouseLeave: handleClose }}
        >
          <MenuItem
            className="d-flex d-lg-none"
            autoFocus={false}
            //  onClick={onClickGetYourCard}
          >
            <a href="/signup">Apply Now</a>
          </MenuItem>
          <MenuItem
            autoFocus={false}
            // onClick={() =>
            //   onClickMenuLinks(
            //     `${REACT_APP_MENULINK}${LOGIN_MAKE_PAYMENT}`,
            //     "_self"
            //   )
            // }
          >
            <a href={`${LOGIN_MAKE_PAYMENT}`}>Login</a>
          </MenuItem>
          <MenuItem
            autoFocus={false}
            // onClick={() => onClickMenuLinks(`${ARMY_LINK}`, "_self")}
          >
            <a href={`${ARMY_LINK}`}>Stashfin Sentinel</a>
          </MenuItem>

          <MenuItem autoFocus={false} onClick={() => window.location.assign(CHECK_CIBIL_REPORT)}>
            <a>Check CIBIL Report</a>
          </MenuItem>
          <MenuItem
            autoFocus={false}
            className="d-flex d-lg-none"
            // onClick={() => onClickMenuLinks(`${REACT_APP_MENULINK}${LOGIN_MAKE_PAYMENT}`, "_self")}
          >
            <a href={`${LOGIN_MAKE_PAYMENT}`}>Make a Payment</a>
          </MenuItem>
          <MenuItem
            autoFocus={false}
            className="d-flex d-lg-none"
            //  onClick={() => onClickMenuLinks(`${LOAN_CALCULATOR}`, "_self")}
          >
            <a href={`${LOAN_CALCULATOR}`}>Calculate EMI </a>
          </MenuItem>
          <MenuItem
            autoFocus={false}
            className="d-flex d-lg-none"
            onClick={() => onClickMenuLinks(`${ABOUT_US}`, "_self")}
          >
            <a href={`${ABOUT_US}`}>About Us</a>
          </MenuItem>
          <MenuItem
            autoFocus={false}
            className="d-flex d-lg-none"
            //  onClick={() => onClickMenuLinks(`${FOUNDER_LINK}`, "_self")}
          >
            <a href={`${FOUNDER_LINK}`}>Meet Our Founders</a>
          </MenuItem>
          <MenuItem autoFocus={false}>
            <a href={`${REFERRAL_PROGRAM}`}>Refer and Earn</a>
          </MenuItem>

          <MenuItem
            autoFocus={false}
            // onClick={() => onClickMenuLinks(`${NEWS_ROOM_LINK}`, "_self")}
          >
            <a href={`${NEWS_ROOM_LINK}`}>Newsroom</a>
          </MenuItem>
          <MenuItem
            autoFocus={false}
            //  onClick={() => onClickMenuLinks(`${CUSTOMER_SERVICE}`, "_self")}
          >
            <a href={`${CUSTOMER_SERVICE}`}> Customer Service</a>
          </MenuItem>
          <MenuItem
            autoFocus={false}
            //  onClick={() => onClickMenuLinks(`${CUSTOMER_SERVICE}`, "_self")}
          >
            <a href={`${INSURANCE}`}>Insurance</a>
          </MenuItem>
        </Menu>
      </div>
    </>
  );
};

export default HeaderLinks;