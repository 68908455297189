/* eslint-disable complexity */
/* eslint-disable no-case-declarations */

import {
  KYC_DETAILS_SUBMIT_FORM,
  KYC_DETAILS_SUBMIT_FORM_SUCCESS,
  KYC_DETAILS_SUBMIT_FORM_ERROR,
  SET_SCREEN_DETAIL,
  KYC_DETAILS_SUCCESS,
  KYC_DETAILS_ERROR,
  KYC_DETAILS,
  OKYC_DETAILS,
  OKYC_DETAILS_SUCCESS,
  OKYC_DETAILS_ERROR,
  SET_BUTTON_LOADING,
  SET_ERROR_MESSAGE,
  USER_DETAILS_SUCCESS,
  USER_DETAILS_ERROR,
  GET_CITY_NAME_SUCCESS,
  GET_CITY_NAME_ERROR,
  GET_CITY_NAME,
  GET_KYC_REDIRECT_URL,
  GET_KYC_REDIRECT_URL_SUCCESS,
  GET_KYC_REDIRECT_URL_ERROR,
  SET_NO_CHANGE_IN_KYC,
  SET_NO_CHANGE_IN_KYC_SUCCESS,
  SET_NO_CHANGE_IN_KYC_ERROR
} from "./constants";

const initialState = {
  loader: false,
  token: null,
  deviceId: null,
  okycDetails: [],
  kycDetails: [],
  redirectDetails: [],
  kycUserDetails: [],
  formData: "",
  noChanges: "",
  modeValue: "",
  checksumValue: "",
  versionId: "v2",
  isLoading: false,
  error: "",
  prevScreen: null,
  currScreen: null,
  pincode: "",
  cityName: "",
  errorInGettingCity: false,
  kyc_redirect_url: "",
  kyc_redirect_url_message: ""
};

const KYCReducer = (state = initialState, action = {}) => {
  const { type, payload } = action;
  switch (type) {
    case KYC_DETAILS_SUBMIT_FORM:
      return {
        ...state,
        noChanges: payload?.noChanges,
        formData: payload?.formDatas,
        loader: true
      };
    case KYC_DETAILS_SUBMIT_FORM_SUCCESS:
      return {
        ...state,
        loader: false,
        kycDetails: payload
      };

    case KYC_DETAILS_SUBMIT_FORM_ERROR:
      return {
        ...state,
        loader: false,
        error: payload?.error
      };

    case SET_SCREEN_DETAIL:
      return {
        ...state,
        prevScreen: payload?.prevScreen,
        currScreen: payload?.currScreen
      };
    case KYC_DETAILS:
      return {
        ...state,
        loader: true
      };
    case KYC_DETAILS_SUCCESS:
      return {
        ...state,
        redirectDetails: payload,
        loader: false
      };

    case KYC_DETAILS_ERROR:
      return {
        ...state,
        loader: false,
        error: payload?.error
      };

    case OKYC_DETAILS:
      return {
        ...state,
        token: payload?.utmData?.token,
        deviceId: payload?.utmData?.deviceId,
        modeValue: payload?.payload?.mode,
        checksumValue: payload?.payload?.checksum,
        loader: true
      };
    case OKYC_DETAILS_SUCCESS:
      return {
        ...state,
        okycDetails: payload,
        loader: false
      };

    case OKYC_DETAILS_ERROR:
      return {
        ...state,
        loader: false,
        error: payload?.error
      };

    case SET_NO_CHANGE_IN_KYC:
      return {
        ...state,
        token: payload?.utmData?.token,
        deviceId: payload?.utmData?.deviceId,
        modeValue: payload?.payload?.mode,
        checksumValue: payload?.payload?.checksum,
        loader: true
      };
    case SET_NO_CHANGE_IN_KYC_SUCCESS:
      return {
        ...state,
        loader: false
      };

    case SET_NO_CHANGE_IN_KYC_ERROR:
      return {
        ...state,
        loader: false,
        error: payload?.error
      };

    case SET_BUTTON_LOADING:
      return {
        ...state,
        isLoading: payload
      };

    case SET_ERROR_MESSAGE:
      return {
        ...state,
        error: ""
      };

    case USER_DETAILS_SUCCESS:
      return {
        ...state,
        kycUserDetails: payload
      };

    case USER_DETAILS_ERROR:
      return {
        ...state,
        error: ""
      };

    case GET_CITY_NAME:
      return {
        ...state,
        pincode: payload
      };

    case GET_CITY_NAME_SUCCESS:
      return {
        ...state,
        cityName: payload,
        errorInGettingCity: false
      };

    case GET_CITY_NAME_ERROR:
      return {
        ...state,
        cityName: "",
        error: payload.error,
        errorInGettingCity: true
      };

    case GET_KYC_REDIRECT_URL:
      return {
        ...state,
        loader: true
      };

    case GET_KYC_REDIRECT_URL_SUCCESS:
      return {
        ...state,
        loader: false,
        kyc_redirect_url: payload
      };

    case GET_KYC_REDIRECT_URL_ERROR:
      return {
        ...state,
        loader: false,
        kyc_redirect_url_message: payload
      };

    default:
      return state;
  }
};

export default KYCReducer;
