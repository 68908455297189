export const CLI_VERIFY_MOBILE = 'CLI_VERIFY_MOBILE';
export const CLI_VERIFY_MOBILE_SUCCESS = 'CLI_VERIFY_MOBILE_SUCCESS';
export const CLI_VERIFY_MOBILE_ERROR = 'CLI_VERIFY_MOBILE_ERROR';
export const CLI_SEND_OTP = 'CLI_SEND_OTP';
export const CLI_SEND_OTP_SUCCESS = 'CLI_SEND_OTP_SUCCESS';
export const CLI_SEND_OTP_ERROR = 'CLI_SEND_OTP_ERROR';
export const VERIFY_CLI = 'VERIFY_CLI';
export const VERIFY_CLI_RESPONSE = 'VERIFY_CLI_RESPONSE';
export const VERIFY_CLI_SUCCESS = 'VERIFY_CLI_SUCCESS';
export const VERIFY_CLI_ERROR = 'VERIFY_CLI_ERROR';
export const CLOSE_EVENT = 'CLOSE_EVENT';
export const LOAD_EVENT = 'LOAD_EVENT';
