import React from 'react';
import '../Style/BottomSheetPopup.scss';

const BottomSheetPopup = ({ children }) => (
  <div className={`bottomOverlay`}>
    <div className="bottomsheet">{children}</div>
  </div>
);

export default BottomSheetPopup;
