import {} from '../../../utils/constant'
  
  const initialState = {
    bankList:[],
    bankListError:{},
    message:"",
    status:"",
    bankSelected:"",
    bankId:"",
    bankTenure:"",
    year_from:"",
    year_to:"",
    year_month_to:"",
    year_month_from:"",
    month_to_char:"",
    month_from_char:"",
    onlineBankingPayload:"",
    onlineBankingStatus:"",
    fileUploadsPayload:"",
    fileUploadsStatus:""
  }
  
  const bankingReducer = (state = initialState, {type,payload}) => {
    switch (type) {
      case "GET_BANKLIST_SUCCESS":
        state = { ...state, bankList:payload.banks,status:payload.statement_status,message:payload.message,year_month_from:payload.year_month_from,year_month_to:payload.year_month_to,onlineBankingStatus:"",fileUploadsStatus:""}
        break
      case "GET_BANK_SELECTED":
        state = { ...state, bankSelected:payload.name,bankId:payload.bankId}
        break
      case "GET_BANK_TENURE":
        state = { ...state, bankTenure:payload.tenure,year_month_to:payload.year_month_to,year_month_from:payload.year_month_from,month_to_char:payload.month_to_char,month_from_char:payload.month_from_char,year_from:payload.year_from,year_to:payload.year_to}
        break
      case "GET_BANKLIST_ERROR":
        state = { ...state, bankListError: payload,onlineBankingStatus:"",fileUploadsStatus:""}
        break
      case "POST_ONLINEBANKING_SUCCESS":
        state = {...state,onlineBankingPayload:payload,onlineBankingStatus:payload?.status,fileUploadsStatus:"" }
        break
      case "POST_ONLINEBANKING_ERROR":
          state={...state,onlineBankingStatus:payload?.status,onlineBankingPayload:payload,fileUploadsStatus:""}
          break
      case "POST_FILEUPLOADS_SUCCESS":
        state={...state,fileUploadsStatus:payload?.status,fileUploadsPayload:payload}
        break
      case "POST_FILEUPLOADS_ERROR":
          state={...state,fileUploadsStatus:payload?.status,fileUploadsPayload:payload}
          break  
      default:
        state = { ...state }
        break
    }
    return state
  }
  
  export default bankingReducer
  