export function setAuthToken(authToken) {
  return localStorage.setItem('authToken', authToken);
}

export function getAuthToken() {
  return localStorage.getItem('authToken');
}

export function deleteAuthToken() {
  return localStorage.removeItem('authToken');
}

export function setPhone(phone) {
  return localStorage.setItem('phone', phone);
}

export function getPhone() {
  return localStorage.getItem('phone');
}

export function deletePhone() {
  return localStorage.removeItem('phone');
}

export function setUTMSource(utmSource) {
  return localStorage.setItem('utmSource', utmSource);
}

export function setUTMCampaign(utmCampaign) {
  return localStorage.setItem('utmCampaign', utmCampaign);
}

export function setUTMMedium(utmMedium) {
  return localStorage.setItem('utmMedium', utmMedium);
}

export function setReferBy(referBy) {
  return localStorage.setItem('referBy', referBy);
}

export function getUTMSource() {
  return localStorage.getItem('utmSource');
}

export function getUTMCampaign() {
  return localStorage.getItem('utmCampaign');
}

export function getUTMMedium() {
  return localStorage.getItem('utmMedium');
}

export function getReferBy() {
  return localStorage.getItem('referBy');
}

export function deleteUTMSource() {
  return localStorage.removeItem('utmSource');
}

export function deleteUTMCampaign() {
  return localStorage.removeItem('utmCampaign');
}

export function deleteReferBy() {
  return localStorage.removeItem('referBy');
}

export function setOTPVerified(isVerified) {
  return localStorage.setItem('otpVerified', isVerified);
}

export function getOTPVerified() {
  return localStorage.getItem('otpVerified');
}

export function deleteOTPVerified() {
  return localStorage.removeItem('otpVerified');
}
