import { call, put, takeLatest, select } from 'redux-saga/effects';

import {
  CREATE_TICKET_SUBMIT_FORM,
  CREATE_TICKET_SUBMIT_FORM_ADDITIONAL,
  CUSTOMER_SERVICES,
  POST_CUSTOMER_SERVICE_SUBMIT_FORM_PARAM,
} from '../../../utils/constant';
import { customerServiceApiCall } from '../services';
import { postUploadImg } from '../../../service';
import {
  customerServicePostsSuccess,
  customerServicePostsFail,
  ticketContactUsPostsSuccess,
  ticketContactUsPostsFail,
  customer_service_search,
} from '../actions';

function* customerServicesGet() {
  try {
    let custmservc = yield call(customerServiceApiCall);
    if (custmservc?.data.status === true) {
      localStorage.setItem('saveData', JSON.stringify(custmservc?.data));
      yield put(customerServicePostsSuccess(custmservc?.data));
    } else if (custmservc?.status === false) {
      yield put(customerServicePostsFail(custmservc?.errors));
    }
  } catch (error) {
    yield put(customerServicePostsFail(error));
  }
}
//for create ticket submit form
const stateValue = (state) => state.CustmServiceReducer;

function* createTicketSubmitFormPost() {
  try {
    const stateName = yield select(stateValue);
    const attachment = stateName?.createTicketServicData?.attachment;
    const message = stateName?.createTicketServicData?.message;
    const phone = stateName?.createTicketServicData?.phone;
    const topic_id = stateName?.createTicketServicData?.topic_id;
    let formData = new FormData();
    formData.append('topic_id', topic_id);
    formData.append('message', message);
    formData.append('phone', phone);
    formData.append('source', 'web');
    attachment.forEach((file) => {
      formData.append('attachment', file);
    });
    const response = yield postUploadImg(POST_CUSTOMER_SERVICE_SUBMIT_FORM_PARAM, formData);
    if (response?.status) {
      if (response?.status === 'False') {
        yield put(ticketContactUsPostsFail(response?.message));
      } else {
        yield put(ticketContactUsPostsSuccess(response));
      }
    } else {
      yield put(ticketContactUsPostsFail('Something went wrong please try again'));
    }
  } catch (error) {
    yield put(ticketContactUsPostsFail('Something went wrong please try again'));
  }
}

function* additionalCreateTicketSubmitFormPost() {
  try {
    const stateName = yield select(stateValue);
    const attachment = stateName?.createTicketServicData?.attachment;
    const message = stateName?.createTicketServicData?.message;
    const phone = stateName?.createTicketServicData?.phone;
    const additionalDetail = stateName?.createTicketServicData?.additional_detail;
    const topic_id = stateName?.createTicketServicData?.topic_id;
    const formData = new FormData();
    formData.append('topic_id', topic_id);
    formData.append('message', message);
    formData.append('phone', phone);
    formData.append('additional_detail', additionalDetail);
    formData.append('source', 'web');
    attachment.forEach((file) => {
      formData.append('attachment', file);
    });
    const response = yield postUploadImg(POST_CUSTOMER_SERVICE_SUBMIT_FORM_PARAM, formData);
    if (response?.status) {
      yield put(ticketContactUsPostsSuccess(response));
    } else {
      yield put(ticketContactUsPostsFail(response?.message));
    }
  } catch (error) {
    yield put(ticketContactUsPostsFail('Something went wrong please try again'));
  }
}

export default function* customerServiceSagas() {
  yield takeLatest(CUSTOMER_SERVICES, customerServicesGet);
  yield takeLatest(CREATE_TICKET_SUBMIT_FORM, createTicketSubmitFormPost);
  yield takeLatest(CREATE_TICKET_SUBMIT_FORM_ADDITIONAL, additionalCreateTicketSubmitFormPost);
}
