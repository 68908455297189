import { call, put, takeLatest } from 'redux-saga/effects';
import {
  GET_CUSTOMER_ID,
  GET_CUSTOMER_ID_SUCCESS,
  GET_CUSTOMER_ID_ERROR,
  SUBMIT_IVR_FORM,
  SUBMIT_IVR_FORM_SUCCESS,
  SUBMIT_IVR_FORM_ERROR,
} from './actionTypes';
import { decodeCustomerId, createIvrTicket } from './services';

const commonError = 'Something went wrong, please try again later';

function* getCustomerId({ payload }) {
  try {
    const response = yield call(decodeCustomerId, { payload });
    if (response.status === 200) {
      yield put({ type: GET_CUSTOMER_ID_SUCCESS, payload: response?.data?.results?.message });
    } else {
      yield put({ type: GET_CUSTOMER_ID_ERROR, payload: { error: response?.data?.error_message || commonError } });
    }
  } catch (err) {
    yield put({ type: GET_CUSTOMER_ID_ERROR, payload: { error: commonError } });
  }
}

function* submitIvrForm({ payload }) {
  try {
    const response = yield call(createIvrTicket, { payload });
    if (response.status === 200) {
      yield put({ type: SUBMIT_IVR_FORM_SUCCESS, payload: response?.data });
    } else {
      yield put({ type: SUBMIT_IVR_FORM_ERROR, payload: { error: response?.data?.error_message || commonError } });
    }
  } catch (err) {
    yield put({ type: SUBMIT_IVR_FORM_ERROR, payload: { error: commonError } });
  }
}

export default function* IvrSaga() {
  yield takeLatest(GET_CUSTOMER_ID, getCustomerId);
  yield takeLatest(SUBMIT_IVR_FORM, submitIvrForm);
}
