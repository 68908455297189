import { call, put, takeLatest } from 'redux-saga/effects';
import {
  CLI_SEND_OTP,
  CLI_SEND_OTP_ERROR,
  CLI_SEND_OTP_SUCCESS,
  CLI_VERIFY_MOBILE,
  CLI_VERIFY_MOBILE_ERROR,
  CLI_VERIFY_MOBILE_SUCCESS,
  VERIFY_CLI,
  VERIFY_CLI_ERROR,
  VERIFY_CLI_RESPONSE,
} from './constants';
import { cliSendOtpApi, verifyCliApi } from './services';

const commonError = 'Something went wrong, please try again later';

function* onCliVerifyMobile({ payload }) {
  try {
    const response = yield call(cliSendOtpApi, payload);
    if (response.status === 200) {
      yield put({ type: CLI_VERIFY_MOBILE_SUCCESS, payload: response.data?.message });
    } else if (response.status === 201) {
      yield put({
        type: VERIFY_CLI_RESPONSE,
        payload: { cliEligible: false, message: response.data?.error_message, flag: 'error' },
      });
    } else {
      yield put({ type: CLI_VERIFY_MOBILE_ERROR, payload: response.data?.error_message });
    }
  } catch (err) {
    yield put({ type: CLI_VERIFY_MOBILE_ERROR, payload: err.response.data?.error_message || commonError });
  }
}

function* onCliSendOtp({ payload }) {
  try {
    const response = yield call(cliSendOtpApi, payload);
    if (response.status === 200) {
      yield put({ type: CLI_SEND_OTP_SUCCESS, payload: response.data });
    } else {
      yield put({ type: CLI_SEND_OTP_ERROR, payload: response.data?.error_message });
    }
  } catch (err) {
    yield put({ type: CLI_SEND_OTP_ERROR, payload: err.response.data?.error_message || commonError });
  }
}

function* onVerifyCli({ payload }) {
  try {
    const response = yield call(verifyCliApi, payload);
    if (response.status === 200) {
      const { amount, message } = response.data?.data || {};
      yield put({ type: VERIFY_CLI_RESPONSE, payload: { amount, message, flag: 'success' } });
    } else if (response.status === 201) {
      const { is_cli_eligible, is_otp_verified } = response.data?.data || {};
      if (!is_otp_verified) {
        yield put({ type: VERIFY_CLI_ERROR, payload: response.data?.error_message });
      } else {
        yield put({
          type: VERIFY_CLI_RESPONSE,
          payload: { cliEligible: is_cli_eligible, message: response.data?.error_message, flag: 'error' },
        });
      }
    } else {
      yield put({ type: VERIFY_CLI_ERROR, payload: response.data?.error_message });
    }
  } catch (err) {
    yield put({ type: VERIFY_CLI_ERROR, payload: err.response.data?.error_message || commonError });
  }
}

export default function* CLISagas() {
  yield takeLatest(CLI_VERIFY_MOBILE, onCliVerifyMobile);
  yield takeLatest(CLI_SEND_OTP, onCliSendOtp);
  yield takeLatest(VERIFY_CLI, onVerifyCli);
}
