import {
  GET_LEAD_DETAILS,
  GET_LEAD_DETAILS_SUCCESS,
  GET_LEAD_DETAILS_ERROR,
  SUBMIT_FORM_SUCCESS,
  SUBMIT_FORM_ERROR,
} from './actionTypes';

const initialState = {
  token: '',
  deviceId: '',
  utmData: {},
  loader: true,
  leadDetails: {},
  error: null,
  formSubmit: false,
};

const GoldWaitlistReducer = (state = initialState, action = {}) => {
  const { type, payload } = action;

  switch (type) {
    case GET_LEAD_DETAILS:
      return {
        ...state,
        token: payload?.token,
        deviceId: payload?.deviceId,
        utmData: payload?.utmData,
      };

    case GET_LEAD_DETAILS_SUCCESS:
      return {
        ...state,
        loader: false,
        leadDetails: payload,
      };

    case GET_LEAD_DETAILS_ERROR:
      return {
        ...state,
        loader: false,
        error: payload?.error,
      };

    case SUBMIT_FORM_SUCCESS:
      return {
        ...state,
        formSubmit: true,
      };

    case SUBMIT_FORM_ERROR:
      return {
        ...state,
        error: payload?.error,
      };

    default:
      return state;
  }
};

export default GoldWaitlistReducer;
