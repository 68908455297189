export const getDateMethod = (inputDate) => {
  const day = String(inputDate.getDate()).padStart(2, '0');
  const month = String(inputDate.getMonth() + 1).padStart(2, '0');
  const year = inputDate.getFullYear();

  const formattedDate = `${day}-${month}-${year}`;
  return formattedDate;
};

export const dateFormatter = (inputDateString) => {
  const components = inputDateString?.split('-');
  if (components?.length === 3) {
    const day = components[0];
    const month = components[1];
    const year = components[2];
    const output = `${year}-${month}-${day}`;
    return output;
  }
};

export const productTitleMap = {
  F56D66: 'loan-protection-plan',
  KECU4F: 'wallet-insurance',
  IPQLJZ: 'loan-protection-insurance-lite',
  '5TI3P6': 'loan-protection-insurance',
  C284SA: 'loan-insurance-ultra',
  ZBRQ13: 'income-protection-insurance',
  P5G8Q1: 'travel-insurance',
  YNTEFI: 'ott-insurance',
  GEDIDG: 'loan-emi-lite-insurance',
  '3OPZCU': 'emi-protection-insurance',
  A0O00Z: 'deals-insurance',
  RSCIFH: 'critical-illness-insurance',
  '6JKUZT': 'child-education-insurance',
  W1QO20: 'home-insurance',
  M7R9X2: 'upi-fraud-protection-insurance',
  S8ANQG: 'home-appliance-insurance',
};
