export const GET_TRANSACTION_DETAILS = 'GET_TRANSACTION_DETAILS';
export const GET_TRANSACTION_DETAILS_SUCCESS = 'GET_TRANSACTION_DETAILS_SUCCESS';
export const GET_TRANSACTION_DETAILS_ERROR = 'GET_TRANSACTION_DETAILS_ERROR';

export const GET_ALL_DETAILS = 'GET_ALL_DETAILS';
export const GET_ALL_DETAILS_SUCCESS = 'GET_ALL_DETAILS_SUCCESS';
export const GET_ALL_DETAILS_ERROR = 'GET_ALL_DETAILS_ERROR';

export const SET_CURRENT_SCREEN = 'SET_CURRENT_SCREEN';
export const SET_SELECTED_GAME = 'SET_SELECTED_GAME';
export const SET_GAMES_VIEW = 'SET_GAMES_VIEW';
export const SET_SHOW_GUIDE = 'SET_SHOW_GUIDE';

export const TRANSFER_STASHCASH = 'TRANSFER_STASHCASH';
export const TRANSFER_STASHCASH_SUCCESS = 'TRANSFER_STASHCASHH_SUCCESS';
export const TRANSFER_STASHCASH_ERROR = 'TRANSFER_STASHCASH_ERROR';

export const GET_BANK_DETAILS = 'GET_BANK_DETAILS';
export const GET_BANK_DETAILS_SUCCESS = 'GET_BANK_DETAILS_SUCCESS';
export const GET_BANK_DETAILS_ERROR = 'GET_BANK_DETAILS_ERROR';

export const RESET_TRANSACTION_DETAILS = 'RESET_TRANSACTION_DETAILS';
