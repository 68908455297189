/* eslint-disable max-len */
/* eslint-disable max-statements */
import {
  GET_TRANSACTION_DETAILS,
  GET_TRANSACTION_DETAILS_ERROR,
  GET_TRANSACTION_DETAILS_SUCCESS,
  GET_ALL_DETAILS,
  GET_ALL_DETAILS_SUCCESS,
  GET_ALL_DETAILS_ERROR,
  SET_CURRENT_SCREEN,
  TRANSFER_STASHCASH,
  TRANSFER_STASHCASH_SUCCESS,
  TRANSFER_STASHCASH_ERROR,
  RESET_TRANSACTION_DETAILS,
  GET_BANK_DETAILS,
  GET_BANK_DETAILS_SUCCESS,
  GET_BANK_DETAILS_ERROR,
} from './actionTypes';

const initialState = {
  currentScreen: '',
  loader: false,
  fetchedAllDetails: false,

  stashcashDetail: {},
  errorInFetchingStashcashDetail: false,

  games: [],
  gamesWithTypeCredit: [],
  gamesWithTypeDebit: [],
  errorInFetchingGames: false,

  transactionDetails: [],
  latestTxnEntries: 0,
  errorInFetchingTransactionDetails: false,

  bankDetails: {},
  amountToTransfer: 0,
  transactionStatus: '',
  bankDetailsAuto: {},
};

const GameEngineReducer = (state = initialState, action = {}) => {
  const { type, payload } = action;

  switch (type) {
    case GET_ALL_DETAILS:
      return {
        ...state,
        loader: true,
      };

    case GET_ALL_DETAILS_SUCCESS:
      return {
        ...state,
        loader: false,
        stashcashDetail: payload.getStashCashApiResponse,
        games: payload.getGamesApiResponse,
        gamesWithTypeCredit: payload.getGamesApiResponse.filter(
          (game) => game?.offer_type?.toLowerCase() === 'cashback',
        ),
        gamesWithTypeDebit: payload.getGamesApiResponse.filter(
          (game) => game?.offer_type?.toLowerCase() === 'discount',
        ),
        fetchedAllDetails: true,
      };

    case GET_ALL_DETAILS_ERROR:
      return {
        ...state,
        loader: false,
        errorInFetchingStashcashDetail: true,
      };

    case GET_TRANSACTION_DETAILS:
      return {
        ...state,
        loader: !payload.concatData,
      };

    case GET_TRANSACTION_DETAILS_SUCCESS:
      return {
        ...state,
        loader: false,
        transactionDetails: payload?.concatData ? state.transactionDetails.concat(payload?.data) : payload?.data,
        latestTxnEntries: payload?.data?.length || 0,
      };

    case GET_TRANSACTION_DETAILS_ERROR:
      return {
        ...state,
        loader: false,
        transactionDetails: [],
        errorInFetchingTransactionDetails: true,
      };

    case SET_CURRENT_SCREEN:
      return {
        ...state,
        currentScreen: payload,
      };

    case TRANSFER_STASHCASH:
      return {
        ...state,
        loader: true,
        bankDetails: payload.bankDetails,
        amountToTransfer: payload.amount,
      };

    case TRANSFER_STASHCASH_SUCCESS:
      return {
        ...state,
        loader: false,
        transactionStatus: true,
      };

    case TRANSFER_STASHCASH_ERROR:
      return {
        ...state,
        loader: false,
        transactionStatus: false,
      };
    case RESET_TRANSACTION_DETAILS:
      return {
        ...state,
        transactionStatus: '',
        bankDetails: {},
        amountToTransfer: 0,
      };

    case GET_BANK_DETAILS:
      return {
        ...state,
        loader: true,
      };

    case GET_BANK_DETAILS_SUCCESS:
      return {
        ...state,
        loader: false,
        bankDetailsAuto: payload,
      };

    case GET_BANK_DETAILS_ERROR:
      return {
        ...state,
        loader: false,
        bankDetailsAuto: {},
      };

    default:
      return state;
  }
};

export default GameEngineReducer;
