import {
    GET_COMMONDETAIL,
    GET_COMMONDETAIL_SUCCESS,
    GET_COMMONDETAIL_ERROR,
    POST_COMMONDETAIL,
    POST_COMMONDETAIL_SUCCESS,
    POST_COMMONDETAIL_ERROR
  } from '../../../utils/constant';
  
  export const commonDetailsGet = (get) => {
    return {
      type: GET_COMMONDETAIL,
      payload: get,
    };
  };
  
  export const commonDetailsgetSuccess = (get) => {
    return {
      type: GET_COMMONDETAIL_SUCCESS,
      payload: get,
    };
  };
  
  export const commonDetailsgetFail = (error) => {
    return {
      type: GET_COMMONDETAIL_ERROR,
      payload: error,
    };
  };
  
  export const commonDetailsPost = (post) => {
    return {
      type: POST_COMMONDETAIL,
      payload: post,
    };
  };
  
  export const commonDetailsPostSuccess = (postSuccess) => {
    return {
      type: POST_COMMONDETAIL_SUCCESS,
      payload: postSuccess,
    };
  };
  
  export const commonDetailsPostFail = (postError) => {
    return {
      type: POST_COMMONDETAIL_ERROR,
      payload: postError,
    };
  };
  