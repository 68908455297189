import {
    POST_LOGINDETAIL,
    POST_LOGINDETAIL_SUCCESS,
    POST_LOGINDETAIL_ERROR,
    POST_OTPDETAIL,
    POST_OTPDETAIL_SUCCESS,
    POST_OTPDETAIL_ERROR
  } from '../../../utils/constant';
  
  
  export const loginDetailsPost = (post) => {
    return {
      type: POST_LOGINDETAIL,
      payload: post,
    };
  };

  
  export const loginDetailsPostSuccess = (postSuccess) => {
    return {
      type: POST_LOGINDETAIL_SUCCESS,
      payload: postSuccess,
    };
  };
  
  export const loginDetailsPostError = (postError) => {
    return {
      type: POST_LOGINDETAIL_ERROR,
      payload: postError,
    };
  };
  
  export const otpDetailsPost = (post) => {
    return {
      type: POST_OTPDETAIL,
      payload: post,
    };
  };

  export const otpDetailsPostSuccess = (postSuccess) => {
    return {
      type: POST_OTPDETAIL_SUCCESS,
      payload: postSuccess,
    };
  };
  
  export const otpDetailsPostError = (postError) => {
    return {
      type: POST_OTPDETAIL_ERROR,
      payload: postError,
    };
  };