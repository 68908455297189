import axios from 'axios';

const BASE_URL = process.env.REACT_APP_BASE_API_URL;

export const clevertapEventApiCall = async ({ auth_token, eventData }) => {
  try {
    const response = await axios.put(`${BASE_URL}magneto/clevertap/send-event-from-web`, eventData, {
      headers: { auth_token, source: 'web' },
    });
    return response;
  } catch (error) {
    return error;
  }
};
