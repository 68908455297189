import axios from 'axios'
import {
    GET_BANKING_PARAMS,
} from '../../../utils/constant'

export const getBankListApi = async (payload) => {
  try {
      const url = process.env.REACT_APP_BASE_V2_API_URL+GET_BANKING_PARAMS
      const response = await axios.post(url,payload)
    

    if (response.status === true) {
      return response
    }
    if (response.status === false) {
      return response
    }
    return response
  } catch (error) {
    return error.response
  }
}

export const postBankApi = async (payload) => {
  console.log("payload===>",payload)

  try {
    const url = process.env.REACT_APP_BASE_V2_API_URL+GET_BANKING_PARAMS
    const response = await axios.post(url,payload)


  if (response.status === true) {
    return response
  }
  if (response.status === false) {
    return response
  }
  return response
} catch (error) {
  return error.response
}
}


