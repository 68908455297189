import { REFER_AND_EARNS, REFER_AND_EARNS_SUCCESS, REFER_AND_EARNS_FAILURE } from './actionTypes';

const initialState = {
  loader: true,
  token: null,
  deviceId: null,
  modeValue: '',
  checkSumValue: '',
  referAndEarnDetail: [],
  error: null,
};

const referAndEarnReducer = (state = initialState, action = {}) => {
  const { type, payload } = action;
  switch (type) {
    case REFER_AND_EARNS:
      return {
        ...state,
        token: payload?.token,
        deviceId: payload?.device_id,
        modeValue: payload?.payload.mode,
        checkSumValue: payload?.payload.checksum,
      };
    case REFER_AND_EARNS_SUCCESS:
      return {
        ...state,
        loader: false,
        referAndEarnDetail: payload,
      };
    case REFER_AND_EARNS_FAILURE:
      return {
        ...state,
        loader: false,
        error: payload?.error,
      };
    default:
      return state;
  }
};

export default referAndEarnReducer;
