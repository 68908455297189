import axios from 'axios';
import { COMMENT, GET_OTP_PARAMS, VARIFY_OTP_PARAMS } from '../../../utils/constant';

export const saveComment = async (data) => {
  try {
    let payload = new FormData();
    payload.append('ticket_id', data.t_id);
    payload.append('comment', data.comment);
    if (data.uploadedFiles.length) {
      data.uploadedFiles.map((file) => {
        payload.append('uploaded_documents', file);
      });
    }

    if (data.reopen) {
      payload.append('is_reopen', true);
    } else {
      payload.append('is_reopen', false);
    }

    if (data.rescheduleTime) {
      payload.append('call_schedule', data.rescheduleTime);
    }

    let response = await axios.post(process.env.REACT_APP_ADMIN_PANEL_API + `${COMMENT}`, payload);
    return response.data;
  } catch (e) {
    return false;
  }
};

export const sendOTPFunc = async (payload) => {
  try {
    const response = await axios.post(`${process.env.REACT_APP_BASE_V3_API_URL}${GET_OTP_PARAMS}`, payload);
    return response;
  } catch (e) {
    return false;
  }
};

export const varifyOTPFunc = async (payload) => {
  try {
    const response = await axios.post(`${process.env.REACT_APP_BASE_V3_API_URL}${VARIFY_OTP_PARAMS}`, payload);
    return response;
  } catch (e) {
    return false;
  }
};
