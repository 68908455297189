import axios from 'axios';

const BASE_URL = process.env.REACT_APP_MAGNETO_API;

export const cliSendOtpApi = async (payload) => {
  try {
    const res = await axios.post(`${BASE_URL}cli/send-otp`, payload);
    return res;
  } catch (err) {
    return err.response;
  }
};

export const verifyCliApi = async (payload) => {
  try {
    const res = await axios.post(`${BASE_URL}cli/verify-cli`, payload);
    return res;
  } catch (err) {
    return err.response;
  }
};
