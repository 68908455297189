export const LOAN_RESTRUCTURE_DETAILS = 'LOAN_RESTRUCTURE_DETAILS';
export const LOAN_RESTRUCTURE_DETAILS_SUCCESS = 'LOAN_RESTRUCTURE_DETAILS_SUCCESS';
export const LOAN_RESTRUCTURE_DETAILS_ERROR = 'LOAN_RESTRUCTURE_DETAILS_ERROR';
export const LOAN_RESTRUCTURE_SEND_OTP = 'LOAN_RESTRUCTURE_SEND_OTP';
export const LOAN_RESTRUCTURE_RESEND_SEND_OTP = 'LOAN_RESTRUCTURE_RESEND_SEND_OTP';
export const LOAN_RESTRUCTURE_SEND_OTP_SUCCESS = 'LOAN_RESTRUCTURE_SEND_OTP_SUCCESS';
export const LOAN_RESTRUCTURE_SEND_OTP_ERROR = 'LOAN_RESTRUCTURE_SEND_OTP_ERROR';
export const VERIFY_LOAN_RESTRUCTURE = 'VERIFY_LOAN_RESTRUCTURE';
export const VERIFY_LOAN_RESTRUCTURE_SUCCESS = 'VERIFY_LOAN_RESTRUCTURE_SUCCESS';
export const VERIFY_LOAN_RESTRUCTURE_ERROR = 'VERIFY_LOAN_RESTRUCTURE_ERROR';
export const HANDLE_FLAG = 'HANDLE_FLAG';
