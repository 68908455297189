import {
  SAVE_COMMENT,
  SAVE_COMMENT_SUCCESS,
  SAVE_COMMENT_FAIL,
  SEND_OTP_ACTION,
  VARIFY_OTP_ACTION,
} from '../../../utils/constant';

  export const saveComment = (post) => {
    return {
      type: SAVE_COMMENT,
      payload: post
    }
  }

  export const saveCommentSuccess = (post) => {
    return {
      type: SAVE_COMMENT_SUCCESS,
      payload: post
    }
  }

export const saveCommentFail = (error) => ({
  type: SAVE_COMMENT_FAIL,
  payload: error,
});

export const sendOTP = (payload) => ({
  type: SEND_OTP_ACTION,
  payload,
});

export const varifyOTP = (payload) => ({
  type: VARIFY_OTP_ACTION,
  payload,
});
