import React from 'react';
import { Provider } from 'react-redux';
import ReactDOM from 'react-dom/client';
import * as Sentry from '@sentry/react';
import { BrowserRouter } from 'react-router-dom';

import App from './pages/App';
import { store } from './store';
import reportWebVitals from './reportWebVitals';
// import * as serviceWorkerRegistration from './serviceWorkerRegistration';

import './index.scss';
import 'bootstrap/dist/css/bootstrap.min.css';

Sentry.init({
  dsn: "https://8ea8702039db83b62f7c42701788fd08@sentry.stashfin.com/32",
  integrations: [
    new Sentry.BrowserTracing(),
    new Sentry.Replay()
  ],
  tracesSampleRate: 1.0,
});

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Provider store={store}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
  </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals

reportWebVitals();

// serviceWorkerRegistration.unregister();
// serviceWorkerRegistration.register();
