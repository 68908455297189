import {
  CUSTOMER_SERVICES,
  CUSTOMER_SERVICES_SUCCESS,
  CUSTOMER_SERVICES_ERROR,
  CREATE_TICKET_SUBMIT_FORM,
  CREATE_TICKET_SUBMIT_FORM_SUCCESS,
  CREATE_TICKET_SUBMIT_FORM_ERROR,
  CLEAR_TICKET_ERROR,
  CUSTOMER_SERVICE_SEARCH,
  CUSTOMER_SERVICE_SEARCH_LIST,
  CUSTOMER_SERVICE_RAISE_TICKET_INPUT,
  CUSTOMER_SERVICE_RAISE_TICKET_INPUT_NULL,
  CREATE_TICKET_SUBMIT_FORM_ADDITIONAL,
} from '../../../utils/constant';

export const customerServicePosts = (get) => ({
  type: CUSTOMER_SERVICES,
  payload: get,
});

export const customerServicePostsSuccess = (get) => ({
  type: CUSTOMER_SERVICES_SUCCESS,
  payload: get,
});

export const customerServicePostsFail = (error) => ({
  type: CUSTOMER_SERVICES_ERROR,
  payload: error,
});
// customer service ticket submit form action
export const ticketContactUsPosts = (posts) => ({
  type: CREATE_TICKET_SUBMIT_FORM,
  payload: posts,
});
export const additionalTicketContactUsPosts = (posts) => ({
  type: CREATE_TICKET_SUBMIT_FORM_ADDITIONAL,
  payload: posts,
});
export const ticketContactUsPostsSuccess = (posts) => ({
  type: CREATE_TICKET_SUBMIT_FORM_SUCCESS,
  payload: posts,
});
export const ticketContactUsPostsFail = (error) => ({
  type: CREATE_TICKET_SUBMIT_FORM_ERROR,
  payload: error,
});
export const clearTicketError = () => ({
  type: CLEAR_TICKET_ERROR,
  payload: '',
});

export const customerServiceSearch = (posts) => ({
  type: CUSTOMER_SERVICE_SEARCH,
  payload: posts,
});
export const customerServiceSearchList = (posts) => ({
  type: CUSTOMER_SERVICE_SEARCH_LIST,
  payload: posts,
});
export const customerServiceRaiseTicketInput = (posts) => ({
  type: CUSTOMER_SERVICE_RAISE_TICKET_INPUT,
  payload: posts,
});
export const customerServiceRaiseTicketInputNull = () => ({
  type: CUSTOMER_SERVICE_RAISE_TICKET_INPUT_NULL,
});
