export function navigateToPage(payload) {
  if (typeof cbBridge !== 'undefined') {
    /* eslint-disable no-undef */
    cbBridge.navigateToPage(JSON.stringify(payload));
    /* eslint-enable no-undef */
  } else {
    console.error('cbBridge object is not defined');
  }
}

export function initiatePayment(payload) {
  if (typeof cbBridge !== 'undefined') {
    /* eslint-disable no-undef */
    cbBridge.initiatePayment(JSON.stringify(payload));
    /* eslint-enable no-undef */
  } else {
    console.error('cbBridge object is not defined');
  }
}

export function sendFirebaseEvent(eventName, payload) {
  if (typeof cbBridge !== 'undefined') {
    /* eslint-disable no-undef */
    cbBridge.sendAnalytics(eventName, JSON.stringify(payload));
    /* eslint-enable no-undef */
  } else {
    console.error('cbBridge object is not defined');
  }
}
