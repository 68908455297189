import axios from "axios";

const BASE_URL = process.env.REACT_APP_BASE_API_URL;
const BASE_URL_NODE = process.env.REACT_APP_NODE_URL;

export const kycDetailVerificationApiCall = async ({ payload }) => {
  try {
    const res = await axios.post(`${BASE_URL}journey/rekyc/detail-verification`, JSON.stringify(payload), {
      headers: {
        auth_token: window.localStorage.getItem("auth_token"),
        device_id: window.localStorage.getItem("device_id"),
        "Content-Type": "application/json",
        accept: "application/json"
      }
    });
    return res;
  } catch (err) {
    return err.response;
  }
};

export const kycRedirectUrlApiCall = async () => {
  try {
    const res = await axios.get(`${BASE_URL}journey/rekyc/redirect-url`, {
      headers: {
        auth_token: window.localStorage.getItem("auth_token"),
        device_id: window.localStorage.getItem("device_id")
      }
    });
    return res;
  } catch (err) {
    return err.response;
  }
};

export const okycDetailApiCall = async ({ versionId, payload }) => {
  try {
    const res = await axios.post(`${BASE_URL}v2/api`, payload, {
      headers: {
        auth_token: window.localStorage.getItem("auth_token"),
        device_id: window.localStorage.getItem("device_id"),
        version: versionId,
        "Content-Type": "application/x-www-form-urlencoded"
      }
    });
    return res;
  } catch (err) {
    return err.response;
  }
};

export const rekycUserDetailsApiCall = async () => {
  try {
    const res = await axios.get(`${BASE_URL}journey/rekyc/user-details`, {
      headers: {
        auth_token: window.localStorage.getItem("auth_token"),
        device_id: window.localStorage.getItem("device_id")
      }
    });
    return res;
  } catch (err) {
    return err.response;
  }
};

export const getCityNameApiCall = async ({ pincode }) => {
  try {
    const res = await axios.get(`${BASE_URL}journey/rekyc/pincode-details?pin_code=${pincode?.pincode}`, {
      headers: {
        auth_token: window.localStorage.getItem("auth_token"),
        device_id: window.localStorage.getItem("device_id")
      }
    });
    return res;
  } catch (err) {
    return err.response;
  }
};

export const getKycRedirectUrlApiCall = async () => {
  try {
    const res = await axios.get(`${BASE_URL_NODE}customer/v1/okyc/weburl`, {
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem("auth_token")}`
      }
    });
    return res;
  } catch (err) {
    return err.response;
  }
};

export const setNoChangeInKycApiCall = async ({ versionId, payload }) => {
  try {
    const res = await axios.post(`${BASE_URL}v2/api`, payload, {
      headers: {
        auth_token: window.localStorage.getItem("auth_token"),
        device_id: window.localStorage.getItem("device_id"),
        version: versionId,
        "Content-Type": "application/x-www-form-urlencoded"
      }
    });
    return res;
  } catch (err) {
    return err.response;
  }
};
