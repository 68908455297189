import { post, stashfinPost } from "../../../service";
import { GET_POSTS_DEAL_AND_OFFER_URL_PARAM} from "../../../utils/constant";
import { ErrorMessage, SuccessMessage } from "../../../utils/responseMessage";

export const dealsAndOfferApiCall = async (data) => {
  try {

    let formData = new FormData();
    formData.append('mode', 'dealsandoffers');

    const req = {
      body: formData,
    };
    const response = await stashfinPost(GET_POSTS_DEAL_AND_OFFER_URL_PARAM,req.body);
    console.log('response: ', response);
    if (response.status === true) {
      return response;
    }
    if (response.status === false) {
      return response;
    }
    return response;
  } catch (e) {
    ErrorMessage({
      message: e,
    });
    return false;
  }
};
