import {
  GET_DECRYPTED_DATA_SUCCESS,
  INITIATE_PAYMENT,
  INITIATE_PAYMENT_SUCCESS,
  INITIATE_PAYMENT_FAILURE,
  GET_UPI_COLLECT_PAYMENT_STATUS_PENDING,
  GET_UPI_COLLECT_PAYMENT_STATUS_SUCCESS,
  GENERATE_EXTERNAL_PAYMENT_LINK,
  GENERATE_EXTERNAL_PAYMENT_LINK_SUCCESS,
} from './actionTypes';

const initialState = {
  loader: true,
  vpa_id: '',
  txnInProgress: false,
  txnId: '',
  errorInVerifyingVpa: false,
  paymentComplete: false,
  paymentStatus: '',
  externalPaymentRedirectionLink: '',
  decryptedData: {},
  btnLoader: false,
  externalPaymentLinkLoader: false,
};

const UpiCollectReducer = (state = initialState, action = {}) => {
  const { type, payload } = action;

  switch (type) {
    case GET_DECRYPTED_DATA_SUCCESS:
      return {
        ...state,
        decryptedData: payload,
        loader: false,
      };

    case INITIATE_PAYMENT:
      return {
        ...state,
        btnLoader: true,
        vpa_id: payload?.vpa_id,
        errorInVerifyingVpa: false,
      };

    case INITIATE_PAYMENT_SUCCESS:
      return {
        ...state,
        btnLoader: false,
        txnInProgress: true,
        txnId: payload?.transactionId,
      };

    case INITIATE_PAYMENT_FAILURE:
      return {
        ...state,
        btnLoader: false,
        errorInVerifyingVpa: true,
      };

    case GET_UPI_COLLECT_PAYMENT_STATUS_SUCCESS:
      return {
        ...state,
        txnInProgress: false,
        paymentComplete: true,
        paymentStatus: payload?.status,
      };

    case GET_UPI_COLLECT_PAYMENT_STATUS_PENDING:
      return {
        ...state,
        paymentStatus: payload?.status,
      };

    case GENERATE_EXTERNAL_PAYMENT_LINK:
      return {
        ...state,
        externalPaymentLinkLoader: true,
      };

    case GENERATE_EXTERNAL_PAYMENT_LINK_SUCCESS:
      return {
        ...state,
        externalPaymentLinkLoader: false,
      };

    default:
      return state;
  }
};

export default UpiCollectReducer;
