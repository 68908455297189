import { get, post } from '../../../service'
import axios from 'axios'
import {
  GET_BASICDETAIL_URL_PARAM,
  POST_BASICDETAIL_URL_PARAM,
} from '../../../utils/constant'
import { ErrorMessage } from '../../../utils/responseMessage'

export const basicDetailApiCall = async () => {
  try {
    const req = {
      api: process.env.REACT_APP_BASE_V3_API_URL,
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'client-token': window.localStorage.getItem('customer_token'),
      },
    }

    const response = await get(GET_BASICDETAIL_URL_PARAM, req)
    if (response.status === true) {
      return response
    }
    if (response.status === false) {
      return response
    }
    return response
  } catch (error) {
    return error.response
  }
}

export const postBasicDetailApiCall = async (payload) => {
  try {
    const response = await axios.post(window.localStorage.getItem("api"),payload,{
      headers: {
        "Content-Type": "application/json",
        "client-token":window.localStorage.getItem("customer_token")
      }
    });
    if (response.status === true) {
      return response
    }
    if (response.status === false) {
      return response
    }
    return response
  } catch (error) {
    return error.response
  }
}
