import {
  CUSTOMER_SERVICES,
  CUSTOMER_SERVICES_SUCCESS,
  CUSTOMER_SERVICES_ERROR,
  CREATE_TICKET_SUBMIT_FORM,
  CREATE_TICKET_SUBMIT_FORM_SUCCESS,
  CREATE_TICKET_SUBMIT_FORM_ERROR,
  CLEAR_TICKET_ERROR,
  CUSTOMER_SERVICE_SEARCH,
  CUSTOMER_SERVICE_SEARCH_LIST,
  CUSTOMER_SERVICE_RAISE_TICKET_INPUT,
  CUSTOMER_SERVICE_RAISE_TICKET_INPUT_NULL,
  CREATE_TICKET_SUBMIT_FORM_ADDITIONAL,
} from '../../../utils/constant';

const initialState = {
  message: [],
  customServicData: [],
  createTicketServicData: [],
  loading: false,
  loadingSucess: false,
  errorMessage: [],
  lists: [],
  succussTicketServicData: [],
  searchData: [],
  searchListData: [],
  raiseTicketInput: null,
};

const CustmServiceReducer = (state = initialState, action) => {
  switch (action.type) {
    case CUSTOMER_SERVICES:
      state = { ...state, customServicData: action.payload, loading: true };
      break;
    case CUSTOMER_SERVICES_SUCCESS:
      state = { ...state, lists: action.payload, loading: false };
      break;
    case CUSTOMER_SERVICES_ERROR:
      state = {
        ...state,
        errorMessage: action.payload,

        loading: false,
      };
      break;

    case CREATE_TICKET_SUBMIT_FORM:
      return {
        ...state,
        createTicketServicData: action.payload,
        loading: true,
        errorMessage: '',
      };
    case CREATE_TICKET_SUBMIT_FORM_ADDITIONAL:
      return {
        ...state,
        createTicketServicData: action.payload,
        loading: true,
        errorMessage: '',
      };
    case CREATE_TICKET_SUBMIT_FORM_SUCCESS:
      state = {
        ...state,
        succussTicketServicData: action.payload,
        loadingSucess: false,
        loading: false,
        errorMessage: '',
      };
      break;
    case CREATE_TICKET_SUBMIT_FORM_ERROR:
      state = {
        ...state,
        errorMessage: action.payload,

        loading: false,
      };
      break;
    case CLEAR_TICKET_ERROR:
      state = { ...state, errorMessage: '', loading: false };
      break;
    case CUSTOMER_SERVICE_SEARCH:
      state = { ...state, searchData: action.payload };
      break;
    case CUSTOMER_SERVICE_SEARCH_LIST:
      state = { ...state, searchListData: action.payload };
      break;
    case CUSTOMER_SERVICE_RAISE_TICKET_INPUT:
      state = { ...state, raiseTicketInput: action.payload };
      break;
    case CUSTOMER_SERVICE_RAISE_TICKET_INPUT_NULL:
      state = { ...state, raiseTicketInput: '' };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default CustmServiceReducer;
