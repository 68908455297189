import {
    GET_COMMONDETAIL,
    GET_COMMONDETAIL_SUCCESS,
    GET_COMMONDETAIL_ERROR,
    GET_COMMONDETAIL_CATCH_ERROR,
    POST_COMMONDETAIL,
    POST_COMMONDETAIL_SUCCESS,
    POST_COMMONDETAIL_ERROR,
  } from '../../../utils/constant'
  
  const initialState = {
    message: [],
    commonDetailsData: [],
    loading: false,
    loadingSucess: false,
    errorMessage:"",
    lists: [],
    postCommonDetailData: [],
    postSuccess: '',
    postErrorMessage: '',
  }
  
  const commonDetailsReducer = (state = initialState, action) => {
    switch (action.type) {
      case GET_COMMONDETAIL:
        state = { ...state, commonDetailsData: action.payload,statusCode:"", loading: true }
        break
      case GET_COMMONDETAIL_SUCCESS:
        state = { ...state, lists: action.payload,errorMessage:"", loadingSucess: false }
        break
      case GET_COMMONDETAIL_ERROR:
        state = { ...state, errorMessage: action.payload, loading: false }
        break
      case POST_COMMONDETAIL:
        state = { ...state, postCommonDetailData: action.payload, loading: true }
        break
      case POST_COMMONDETAIL_SUCCESS:
        state = { ...state, postSuccess: action.payload,postErrorMessage:"", loading: false }
        break
      case POST_COMMONDETAIL_ERROR:
        const postError = action.payload
        let postErrorMessage = {
             status : postError.status,
             message : postError.data.errors
           }
        state = { ...state, postErrorMessage: postErrorMessage, loading: false }
        break
      default:
        state = { ...state }
        break
    }
    return state
  }
  
  export default commonDetailsReducer
  