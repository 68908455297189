import React from 'react';
import Button from '@mui/material/Button';
import './homeblock.scss';

const HomeBlock = (props) => {
  const {
    type,
    images,
    children,
    description,
    buttonText,
    onClick,
    description2,
    description3,
    alt,
  } = props;

  const onClickButtonType = () => {
    return onClick();
  };

  return (
    <>
      {/* comon component */}
      {type === 'type1' ? (
        <div className='HomeBlock text-left'>
          <div className='container'>
            <div className='row flex-row'>
              <div className='col-md-6'>
                <div
                  data-aos='zoom-in'
                  data-aos-duration='3000'
                  className='imageBlock'
                >
                  <img src={images} alt={alt} />
                </div>
              </div>
              <div className='col-md-6'>
                <div className='blockText'>
                  {children}
                  <div className='mobileImage'>
                    <img src={images} alt={alt} />
                  </div>
                  <p data-aos='fade-up' data-aos-duration='900'>
                    {description}
                  </p>
                  {description2 && description3 && (
                     <div>
                    <p
                      className='descSecond mb-0' 
                      data-aos='fade-up'
                      data-aos-duration='900'
                    >
                      {description2?description2:""}
                    </p>
                    <p
                    className='descSecond mt-0'
                    data-aos='fade-up'
                    data-aos-duration='900'
                  >
                    {description3?description3:""}
                  </p>
                  </div>
                  )}

                  <Button
                    data-aos='fade-up'
                    data-aos-duration='1200'
                    className='button'
                    onClick={() => onClickButtonType()}
                  >
                    {buttonText}
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className='HomeBlock text-left'>
          <div className='container'>
            <div className='row flex-row'>
              <div className='col-md-6'>
                <div className='blockText'>
                  {children}
                  <div className='mobileImage'>
                    <img src={images} alt={alt} />
                  </div>
                  <p data-aos='fade-up' data-aos-duration='900'>
                    {description}
                  </p>
                  <Button
                    data-aos='fade-up'
                    data-aos-duration='1200'
                    className='button'
                    onClick={() => onClickButtonType()}
                  >
                    {buttonText}
                  </Button>
                </div>
              </div>
              <div className='col-md-6'>
                <div
                  className='imageBlock text-center'
                  data-aos='zoom-in'
                  data-aos-duration='3000'
                >
                  <img src={images} alt={alt} />
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default HomeBlock;
