import {
  GET_BASICDETAIL,
  GET_BASICDETAIL_SUCCESS,
  GET_BASICDETAIL_ERROR,
  POST_BASICDETAIL,
  POST_BASICDETAIL_SUCCESS,
  POST_BASICDETAIL_ERROR
} from "../../../utils/constant";

const initialState = {
  message: [],
  basicDetailsData: [],
  loading: false,
  loadingSucess: false,
  errorMessage:"",
  lists:[],
  postBasicDetailData:[],
  postSuccess:"",
  postErrorMessage:""
};

const basicDetailsReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_BASICDETAIL:
      state = { ...state, basicDetailsData: action.payload,errorMessage:"", loading: true };
      break;
    case GET_BASICDETAIL_SUCCESS:
      state = { ...state, lists: action.payload,errorMessage:"", loadingSucess: false };
      break;
    case GET_BASICDETAIL_ERROR:
      state = { ...state,errorMessage: action.payload,loading: false,};
      break;
    case POST_BASICDETAIL:
      state = { ...state, postBasicDetailData: action.payload,postErrorMessage:"", loading: true };
      break;
      case POST_BASICDETAIL_SUCCESS:
      state = { ...state, postSuccess: action.payload,postErrorMessage:"", loading: false };
      break;
    case POST_BASICDETAIL_ERROR:
      const postError = action.payload
      let postErrorMessage = {
           status : postError.status,
           message : postError.data.errors
         }
      state = { ...state, postErrorMessage: postErrorMessage, loading: false };
      break;
    default:
      state = { ...state };
    break;
  }
  return state;
};

export default basicDetailsReducer;