import { call, put, select, takeLatest } from 'redux-saga/effects';

import { bodyFormData } from 'src/utils/helper';
import { REFER_AND_EARNS, REFER_AND_EARNS_SUCCESS, REFER_AND_EARNS_FAILURE } from './actionTypes';
import { referAndEarnApiCall } from './services';

const globalState = (state) => state.referAndEarnReducer;
const commonError = 'Something went wrong, please try again later';

function* fetchreferAndEarnCode() {
  try {
    const { token, deviceId, modeValue, checkSumValue } = yield select(globalState);

    const Apireq = {
      mode: modeValue,
      checksum: checkSumValue,
    };
    const formdata = bodyFormData(Apireq);
    const response = yield call(referAndEarnApiCall, { token, deviceId, payload: formdata });
    if (response.status === 200) {
      yield put({ type: REFER_AND_EARNS_SUCCESS, payload: response.data });
    } else if (response.status > 200 && response.status < 500) {
      yield put({
        type: REFER_AND_EARNS_FAILURE,
        payload: { error: response?.data?.error || response?.data?.message || commonError },
      });
    } else {
      yield put({ type: REFER_AND_EARNS_FAILURE, payload: { error: commonError } });
    }
  } catch (error) {
    yield put({ type: REFER_AND_EARNS_FAILURE, payload: { error: commonError } });
  }
}

function* referAndEarnSaga() {
  yield takeLatest(REFER_AND_EARNS, fetchreferAndEarnCode);
}

export default referAndEarnSaga;
