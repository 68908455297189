/* eslint-disable max-len */
import { call, put, delay, select, takeLatest } from 'redux-saga/effects';

import {
  GET_VOUCHER_DETAILS,
  GET_VOUCHER_DETAILS_SUCCESS,
  GET_VOUCHER_DETAILS_FAILURE,
  UPDATE_CART,
  UPDATE_CART_SUCCESS,
  UPDATE_CART_FAILURE,
  GET_CART_DETAILS,
  GET_CART_DETAILS_SUCCESS,
  GET_CART_DETAILS_FAILURE,
  GET_DEALS_AND_OFFERS_LIST,
  GET_DEALS_AND_OFFERS_LIST_SUCCESS,
  GET_DEALS_AND_OFFERS_LIST_FAILURE,
  GET_PAYMENT_DETAILS,
  GET_PAYMENT_DETAILS_SUCCESS,
  GET_PAYMENT_DETAILS_FAILURE,
  GET_ORDER_DETAILS,
  GET_ORDER_DETAILS_SUCCESS,
  GET_ORDER_DETAILS_FAILURE,
  GET_ORDERS_LIST,
  GET_ORDERS_LIST_SUCCESS,
  GET_ORDERS_LIST_FAILURE,
  // GET_PAYMENT_LINK_FOR_DEALS_SUCCESS,
  // GET_PAYMENT_LINK_FOR_DEALS_FAILURE,
  GET_PAYMENT_LINK_FOR_DEALS,
  LOC_DISCOUNT,
  LOC_DISCOUNT_SUCCESS,
  LOC_DISCOUNT_FAILURE,
  CHECK_OKYC_SUCCESS,
  CHECK_OKYC_FAILURE,
} from './actionTypes';
import {
  offersListApiCall,
  voucherDetailsApiCall,
  getCartApiCall,
  updateCartApiCall,
  paymentStatusApiCall,
  ordersApiCall,
  // getPaymentLinkApiCall,
  checkOKycApiCall,
  locDiscountApiCall,
  selectBestOfferApiCall,
} from './services';
import { logFirebaseEventFromNativeInterface } from '../../../utils/helper';
import { getCalculatedValuesForCart, getStatus } from '../Utils/helper';
import { initiatePayment } from '../../../components/sharedComponents/bridge/bridge';

const reducer = (state) => state.DealsAndOffersNewReducer;
const commonError = 'Something went wrong, please try again later';

function* getDealsAndOffersLists() {
  try {
    const response = yield call(offersListApiCall);
    if (response.status === 200 && response?.data?.success) {
      logFirebaseEventFromNativeInterface('firebase_testinggggg_deals', { paymentStatus: 1, categories: 2 });
      yield put({ type: GET_DEALS_AND_OFFERS_LIST_SUCCESS, payload: response.data?.data });
    } else if (response.status > 200 && response.status < 500) {
      yield put({ type: GET_DEALS_AND_OFFERS_LIST_FAILURE, payload: { error: response?.data?.error || commonError } });
    } else {
      yield put({ type: GET_DEALS_AND_OFFERS_LIST_FAILURE, payload: { error: commonError } });
    }
  } catch (error) {
    yield put({ type: GET_DEALS_AND_OFFERS_LIST_FAILURE, payload: { error: commonError } });
  }
}

function* getVoucherDetails({ payload }) {
  try {
    const response = yield call(voucherDetailsApiCall, { uid: payload?.uid });
    if (response.status === 200 && response?.data?.success) {
      logFirebaseEventFromNativeInterface('firebase_testinggggg_vouchers', { paymentStatus: 3, categories: 4 });
      yield put({ type: GET_VOUCHER_DETAILS_SUCCESS, payload: response.data?.data });
    } else if (response.status > 200 && response.status < 500) {
      yield put({
        type: GET_VOUCHER_DETAILS_FAILURE,
        payload: { error: response?.data?.error_message || commonError },
      });
    } else {
      yield put({ type: GET_VOUCHER_DETAILS_FAILURE, payload: { error: commonError } });
    }
  } catch (error) {
    yield put({ type: GET_VOUCHER_DETAILS_FAILURE, payload: { error: commonError } });
  }
}

function* getCartDetails() {
  try {
    const response = yield call(getCartApiCall);
    if (response.status === 200 && response?.data?.success) {
      const finalResponse = response?.data?.data;
      const selectBestOfferPayload = response?.data?.data?.items?.map((item) => ({
        eventName: 'BUY_GIFT_CARD',
        itemAmount: item?.discounted_price,
        eventSubType: item?.uid,
        quantity: item?.quantity,
      }));
      const selectBestOfferResponse = yield call(selectBestOfferApiCall, selectBestOfferPayload);
      if (selectBestOfferResponse?.status === 200) {
        const selectBestOfferData = selectBestOfferResponse?.data;
        finalResponse.items =
          finalResponse?.items?.map((item) => {
            const matchingOffer = selectBestOfferData.find((offer) => offer?.offer?.event_sub_type === item?.uid);
            return matchingOffer
              ? {
                  ...item,
                  discounted_with_offer_price:
                    item.discounted_price -
                    (matchingOffer?.offer?.offer_type?.toLowerCase() === 'discount' ? matchingOffer.amount : 0),
                  selectBestOfferData: { ...matchingOffer, checked: item?.selectBestOfferData?.checked ?? true },
                }
              : item;
          }) || [];
      }
      yield put({ type: GET_CART_DETAILS_SUCCESS, payload: finalResponse });
    } else {
      yield put({
        type: GET_CART_DETAILS_FAILURE,
        payload: { error: response?.data?.error_message || commonError },
      });
    }
  } catch (error) {
    yield put({ type: GET_CART_DETAILS_FAILURE, payload: { error: commonError } });
  }
}

function* updateCart({ payload }) {
  try {
    const { cartData } = yield select(reducer);
    const response = yield call(updateCartApiCall, { payload });
    if (response.status === 200 && response?.data?.success) {
      let updatedCartData = { ...cartData };
      if (payload?.screen === 'cart') {
        let cartItems = Object.values(updatedCartData);
        const selectBestOfferPayload = cartItems
          .map((item) => ({
            eventName: 'BUY_GIFT_CARD',
            itemAmount: item?.discounted_price,
            eventSubType: item?.uid,
            quantity: payload.sku[0].sku_uid === item?.uid ? payload.sku[0].quantity : item?.quantity,
          }))
          .filter((item) => item.quantity > 0);
        const selectBestOfferResponse = yield call(selectBestOfferApiCall, selectBestOfferPayload);
        if (selectBestOfferResponse?.status === 200) {
          const selectBestOfferResponseData = selectBestOfferResponse?.data;
          cartItems = cartItems.map((item) => {
            const matchingOffer = selectBestOfferResponseData.find(
              (offer) => offer?.offer?.event_sub_type === item?.uid,
            );
            if (matchingOffer)
              return {
                ...item,
                discounted_with_offer_price:
                  item.discounted_price -
                  (matchingOffer?.offer?.offer_type?.toLowerCase() === 'discount' ? matchingOffer.amount : 0),
                selectBestOfferData: { ...matchingOffer, checked: item?.selectBestOfferData?.checked ?? true },
              };
            const { selectBestOfferData, ...rest } = item;
            return rest;
          });
          updatedCartData = Object.fromEntries(cartItems.map((item) => [item.uid, item]));
        }
      }
      updatedCartData[payload.sku[0].sku_uid] = {
        ...updatedCartData[payload.sku[0].sku_uid],
        quantity: payload.sku[0].quantity,
      };
      yield put({ type: UPDATE_CART_SUCCESS, payload: updatedCartData });
    } else {
      yield put({
        type: UPDATE_CART_FAILURE,
        payload: { error: response?.data?.error_message || commonError },
      });
    }
  } catch (error) {
    yield put({ type: UPDATE_CART_FAILURE, payload: { error: commonError } });
  }
}

function* getPaymentLink() {
  try {
    const { cartData, cartDetails } = yield select(reducer);
    const { is_discount_cta_available = false } = cartDetails?.discount || {};
    const { totalDiscountedAmount, finalAmount } = getCalculatedValuesForCart(cartData, cartDetails);
    let totalDiscount = 0;
    let totalCashback = 0;
    const offerDiscountItems = Object.values(cartData)
      .filter((product) => product?.selectBestOfferData?.checked)
      .map((product) => {
        if (product?.selectBestOfferData?.offer?.offer_type?.toLowerCase() === 'discount')
          totalDiscount += product.selectBestOfferData.amount;
        if (product?.selectBestOfferData?.offer?.offer_type?.toLowerCase() === 'cashback')
          totalCashback += product.selectBestOfferData.amount;
        return {
          amount: product?.discounted_price ?? null,
          quantity: product?.quantity ?? null,
          offerId: product?.selectBestOfferData?.offer?.id ?? null,
        };
      });
    let offerDiscount = null;
    if (offerDiscountItems.length)
      offerDiscount = btoa(
        JSON.stringify({ items: offerDiscountItems, discount: totalDiscount, cashback: totalCashback }),
      );
    const response = yield call(checkOKycApiCall);
    if (response.status === 200 && response?.data?.success) {
      if (response?.data?.data?.action === 'payment') {
        initiatePayment({
          amount: is_discount_cta_available ? totalDiscountedAmount : finalAmount,
          paymentFlag: 36,
          redirectionLink: `https://${window.location.hostname}/deals-and-offers/payment-status`,
          offerDiscount,
        });
      }
      yield put({
        type: CHECK_OKYC_SUCCESS,
        payload: response?.data?.data,
      });
    } else {
      yield put({
        type: CHECK_OKYC_FAILURE,
        payload: { error: response?.data?.error_message || commonError },
      });
    }
  } catch (error) {
    yield put({ type: CHECK_OKYC_FAILURE, payload: { error: commonError } });
  }
}

function* getPaymentDetails() {
  let count = 0;
  while (true) {
    try {
      const { orderUidForPaymentStatus, paymentStatusApiPolling, paymentDone } = yield select(reducer);
      if (!paymentStatusApiPolling) break;
      const response = yield call(paymentStatusApiCall, { orderUidForPaymentStatus });
      if (response.status === 200 && response?.data?.success) {
        if (!paymentDone) {
          const paymentStatus = getStatus(response?.data?.data?.status);
          const categories = response?.data?.data?.data?.map((item) => item.name).join(', ');
          logFirebaseEventFromNativeInterface('Giftcard_purchased', { paymentStatus, categories });
        }
        yield put({ type: GET_PAYMENT_DETAILS_SUCCESS, payload: response?.data?.data });
        if ([2, 3].includes(response?.data?.data?.status)) break;
      } else {
        yield put({ type: GET_PAYMENT_DETAILS_FAILURE, payload: { error: commonError } });
      }
      count += 1;
      if (count >= 10) break;
    } catch (err) {
      // Do nothing
    }
    yield delay(30000);
  }
}

function* getOrderDetails() {
  try {
    const { selectedOrderUidForDetail } = yield select(reducer);
    const response = yield call(ordersApiCall, { order_uid: selectedOrderUidForDetail });
    if (response.status === 200) {
      yield put({ type: GET_ORDER_DETAILS_SUCCESS, payload: response?.data?.data });
    } else if (response.status > 200 && response.status < 500) {
      yield put({ type: GET_ORDER_DETAILS_FAILURE, payload: { error: response?.data?.error || commonError } });
    } else {
      yield put({ type: GET_ORDER_DETAILS_FAILURE, payload: { error: commonError } });
    }
  } catch (error) {
    yield put({ type: GET_ORDER_DETAILS_FAILURE, payload: { error: commonError } });
  }
}

function* getOrdersList() {
  try {
    const response = yield call(ordersApiCall);
    if (response.status === 200 && response?.data?.success) {
      yield put({ type: GET_ORDERS_LIST_SUCCESS, payload: response?.data?.data?.items || [] });
    } else if (response.status > 200 && response.status < 500) {
      yield put({
        type: GET_ORDERS_LIST_FAILURE,
        payload: { error: response?.data?.error_message || commonError },
      });
    } else {
      yield put({ type: GET_ORDERS_LIST_FAILURE, payload: { error: commonError } });
    }
  } catch (error) {
    yield put({ type: GET_ORDERS_LIST_FAILURE, payload: { error: commonError } });
  }
}

function* locDiscount({ payload }) {
  try {
    const response = yield call(locDiscountApiCall, { payload });
    if (response.status === 200 && response?.data?.success) {
      yield put({ type: LOC_DISCOUNT_SUCCESS, payload });
    } else if (response.status > 200 && response.status < 500) {
      yield put({
        type: LOC_DISCOUNT_FAILURE,
        payload: { error: response?.data?.error_message || commonError },
      });
    } else {
      yield put({ type: LOC_DISCOUNT_FAILURE, payload: { error: commonError } });
    }
  } catch (error) {
    yield put({ type: LOC_DISCOUNT_FAILURE, payload: { error: commonError } });
  }
}

function* DealsAndOffersSaga() {
  yield takeLatest(GET_VOUCHER_DETAILS, getVoucherDetails);
  yield takeLatest(UPDATE_CART, updateCart);
  yield takeLatest(GET_CART_DETAILS, getCartDetails);
  yield takeLatest(GET_DEALS_AND_OFFERS_LIST, getDealsAndOffersLists);
  yield takeLatest(GET_PAYMENT_DETAILS, getPaymentDetails);
  yield takeLatest(GET_PAYMENT_LINK_FOR_DEALS, getPaymentLink);
  yield takeLatest(GET_ORDER_DETAILS, getOrderDetails);
  yield takeLatest(GET_ORDERS_LIST, getOrdersList);
  yield takeLatest(LOC_DISCOUNT, locDiscount);
}

export default DealsAndOffersSaga;
