export const getStatus = (statusCode = null) => {
  switch (statusCode) {
    case 0:
      return 'PENDING';
    case 1:
      return 'INITIATED';
    case 2:
      return 'SUCCESS';
    case 3:
      return 'FAILED';
    case 4:
      return 'CANCELLED';
    default:
      return 'PENDING';
  }
};

export const getCalculatedValuesForCart = (cartData = {}, cartDetails = {}) => {
  const { loc_discount_percent = 0, loc_discount_cap = 0 } = cartDetails?.discount || {};

  let cartSize = 0;
  let totalDiscountedAmount = 0;
  let totalOriginalAmount = 0;
  let totalDiscount = 0;
  let locDiscountOnTotal = 0;
  let finalAmount = 0;

  Object.values(cartData).forEach((p) => {
    cartSize += p.quantity;
    totalOriginalAmount += p.quantity * p.original_price;
    totalDiscountedAmount +=
      p.quantity *
      (p.discounted_price -
        (p?.selectBestOfferData?.checked && p?.selectBestOfferData?.offer?.offer_type?.toLowerCase() === 'discount'
          ? p.selectBestOfferData.amount
          : 0));
  });
  totalDiscount = totalOriginalAmount - totalDiscountedAmount;
  locDiscountOnTotal = Math.round((loc_discount_percent * totalDiscountedAmount) / 100);
  locDiscountOnTotal = locDiscountOnTotal < loc_discount_cap ? locDiscountOnTotal : loc_discount_cap;
  finalAmount = totalDiscountedAmount - locDiscountOnTotal;

  return {
    cartSize,
    totalDiscountedAmount: parseFloat(totalDiscountedAmount.toFixed(2)),
    totalOriginalAmount: parseFloat(totalOriginalAmount.toFixed(2)),
    totalDiscount: parseFloat(totalDiscount.toFixed(2)),
    locDiscountOnTotal: parseFloat(locDiscountOnTotal.toFixed(2)),
    finalAmount: parseFloat(finalAmount.toFixed(2)),
  };
};
