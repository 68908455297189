import axios from "axios";

//apply base url for axios
const REACT_APP_APP_URL = process.env.REACT_APP_BASE_V3_API_URL;
const REACT_APP_URL = `${process.env.REACT_APP_REDIRECT_LINK_LIVE}/`;
const REACT_APP_GET_TEMP_API = process.env.REACT_APP_ADMIN_PANEL_API;

const axiosApi = axios.create({
  baseURL: REACT_APP_APP_URL,
});

const axiosgetStasheasyApi = axios.create({
  baseURL: REACT_APP_GET_TEMP_API,
});

const axiosStashfinApi = axios.create({
  baseURL: REACT_APP_URL,
});

axios.interceptors.request.use(function (config) {
  return config;
});

axiosApi.interceptors.response.use(
  (response) => response,
  (error) => Promise.reject(error)
);

export async function get(url, config) {
  return await axiosApi
    .get(url, {
      ...config,
    })
    .then((response) => response.data);
}

export async function getStasheasy(url, config) {
  return await axiosgetStasheasyApi
    .get(url, {
      ...config,
    })
    
}

export async function post(url, config) {
  return await axiosApi
    .post(url, {
      ...config,
    })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      return err.response.data;
    });
}

export async function postStasheasy(url, config) {
  return await axiosgetStasheasyApi
    .post(url, {
      ...config,
    })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      return err.response.data;
    });
}

export async function stashfinPost(url, config) {
  return await axiosStashfinApi
    .post(url, {
      ...config,
    })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      return err.response.data;
    });
}
export async function postUploadImg(url, formData) {
  return await axiosgetStasheasyApi
  .post( url, formData )
  .then((response) => {
    return response.data;
  })
  .catch((err) => {
    return err.response.data;
  });
}
