export const GET_DECRYPTED_DATA = 'GET_DECRYPTED_DATA';
export const GET_DECRYPTED_DATA_SUCCESS = 'GET_DECRYPTED_DATA_SUCCESS';
export const INITIATE_PAYMENT = 'INITIATE_PAYMENT';
export const INITIATE_PAYMENT_SUCCESS = 'INITIATE_PAYMENT_SUCCESS';
export const INITIATE_PAYMENT_FAILURE = 'INITIATE_PAYMENT_FAILURE';
export const GET_UPI_COLLECT_PAYMENT_STATUS = 'GET_UPI_COLLECT_PAYMENT_STATUS';
export const GET_UPI_COLLECT_PAYMENT_STATUS_SUCCESS = 'GET_UPI_COLLECT_PAYMENT_STATUS_SUCCESS';
export const GET_UPI_COLLECT_PAYMENT_STATUS_PENDING = 'GET_UPI_COLLECT_PAYMENT_STATUS_PENDING';
export const GENERATE_EXTERNAL_PAYMENT_LINK = 'GENERATE_EXTERNAL_PAYMENT_LINK';
export const GENERATE_EXTERNAL_PAYMENT_LINK_SUCCESS = 'GENERATE_EXTERNAL_PAYMENT_LINK_SUCCESS';
