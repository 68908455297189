import {
  GET_CUSTOMER_ID,
  GET_CUSTOMER_ID_SUCCESS,
  GET_CUSTOMER_ID_ERROR,
  SUBMIT_IVR_FORM_SUCCESS,
  SUBMIT_IVR_FORM_ERROR,
} from './actionTypes';

const initialState = {
  loader: false,
  customer_id: null,
  customerIdError: false,
  isFormSubmitted: false,
  formSubmitError: false,
  btnLoader: false,
};

const IvrReducer = (state = initialState, action = {}) => {
  const { type, payload } = action;

  switch (type) {
    case GET_CUSTOMER_ID:
      return {
        ...state,
        loader: true,
      };

    case GET_CUSTOMER_ID_SUCCESS:
      return {
        ...state,
        loader: false,
        customer_id: payload,
      };

    case GET_CUSTOMER_ID_ERROR:
      return {
        ...state,
        loader: false,
        customerIdError: true,
      };

    case SUBMIT_IVR_FORM_SUCCESS:
      return {
        ...state,
        btnLoader: false,
        isFormSubmitted: true,
      };

    case SUBMIT_IVR_FORM_ERROR:
      return {
        ...state,
        btnLoader: false,
        formSubmitError: true,
      };

    default:
      return state;
  }
};

export default IvrReducer;
