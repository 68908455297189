import React from 'react';
import './ElevateDisable.scss';
import sad from './images/sad.png';
import cibil from './images/cibil.png';
import giftCard from './images/giftCard.png';
import rightArrow from './images/rightOpenArrow.png';
import { navigateToPage } from '../../components/sharedComponents/bridge/bridge';

function ElevateDisable() {
  const returnBackToApp = () =>
    window.location.assign('https://www.stashfin.com?returnToApp=1&source=elevate-disabled');

  const handleCreditReportClick = () => navigateToPage({ landingPage: 'pcr_launch', type: 'page', data: true });

  return (
    <div className="elevateDisable_container">
      <div className="message_div">
        <img className="sad_img" src={sad}></img>
        <span className="sad_msg_heading">LOC Disabled</span>
        <span className="sad_msg">
          <span className="sad_msg_text">
            We regret to inform you that, due to recent changes in our policy, you no longer meet our eligibility
            requirement and will not be to take a disbursement anymore.
          </span>
          <span className="sad_msg_text">We apologize for the inconvenience caused.</span>
        </span>
      </div>
      <div className="options_div">
        <span className="explore_other_text">Explore other products on Stashfin</span>
        <div className="option-card" onClick={handleCreditReportClick}>
          <img className="option_logo" src={cibil}></img>
          <div className="option_content_div">
            <span className="option_content_heading">Get your Credit Report</span>
            <span className="option_content_text">Know your CIBIL Score and check your credit card & loan history</span>
          </div>
          <img className="right_open_arrow" src={rightArrow}></img>
        </div>
        <div className="option-card">
          <img className="option_logo" src={giftCard}></img>
          <div className="option_content_div">
            <span className="option_content_heading">Get your Gift Card</span>
            <span className="option_content_text">Deals from the top brand at low prices</span>
          </div>
          <img className="right_open_arrow" src={rightArrow}></img>
        </div>
      </div>
      <button className="backToHomeBtn" onClick={returnBackToApp}>
        Back to home
      </button>
    </div>
  );
}

export default ElevateDisable;
