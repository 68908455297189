import axios from 'axios';

const BASE_URL = process.env.REACT_APP_BASE_API_URL;

export const loanRestructureDetailFetchApi = (token) => {
  try {
    const res = axios.get(`${BASE_URL}restructure-loan/get-details`, {
      headers: {
        auth_token: token,
      },
    });
    return res;
  } catch (err) {
    return err.response;
  }
};

export const loanRestrSendOtpApi = async (token) => {
  try {
    const res = await axios.post(`${BASE_URL}restructure-loan/send-otp`, {}, { headers: { auth_token: token } });
    return res;
  } catch (err) {
    return err.response;
  }
};

export const verifyLoanRestrApi = async (payload, token) => {
  try {
    const res = await axios.post(`${BASE_URL}restructure-loan/verify`, payload, {
      headers: {
        auth_token: token,
      },
    });
    return res;
  } catch (err) {
    return err.response;
  }
};
