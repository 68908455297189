import {
    POST_LOGINDETAIL_SUCCESS,
    POST_LOGINDETAIL_ERROR,
    POST_OTPDETAIL_SUCCESS,
    POST_OTPDETAIL_ERROR,
  } from '../../../utils/constant'
  
  const initialState = {
    loginSuccess:"",
    loginError:"",
    otpSuccess:"",
    otpError:"",
  }
  
  const loginReducer = (state = initialState, action) => {
    switch (action.type) {
      case POST_LOGINDETAIL_SUCCESS:
        state = { ...state, loginSuccess:action.payload,loginError:"",otpError:"",otpSuccess:"" }
        break
      case POST_LOGINDETAIL_ERROR:
        state = { ...state, loginError: action.payload }
        break
      case POST_OTPDETAIL_SUCCESS:
        state = { ...state, otpSuccess:action.payload }
        break
      case POST_OTPDETAIL_ERROR:
        state = { ...state, otpError: action.payload }
        break
      case "RESET_LOGIN":
        state =   {
          loginSuccess:"",
          loginError:"",
          otpSuccess:"",
          otpError:"",
        }
        break
      default:
        state = { ...state }
        break
    }
    return state
  }
  
  export default loginReducer
  