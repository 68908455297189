import { Button } from '@mui/material';
import { styled, createTheme } from '@mui/system';

const theme = createTheme({});

export const Buttonred = styled(Button)({
  
  backgroundColor: '#EE0025',
  fontSize: '18px',
  fontWeight: 700,
  borderRadius: '50px',
  padding: '24px 56px',
  color: 'primary' | 'secondary',
  color: '#fff',
  textAlign: 'center',
  lineHeight: '22px',
  textTransform: 'inherit',
  '&:hover': {
    backgroundColor: '#c3001e',
    color: '#fff',
  },
  [theme.breakpoints.down('sm')]: {
    fontSize: '11px',
    padding: '10px 12px !important',
    minWidth: '145px',
  },
  '&:disabled': {
    backgroundColor: 'rgba(0, 0, 102, 0.1)',
    color: ' hwb(240deg 56% 34%)',
  },
});
export const Buttonredsmall = styled(Button)({
  backgroundColor: '#EE0025',
  fontSize: '15px',
  fontWeight: 600,
  borderRadius: '50px',
  padding: '15px 30px',
  color: 'primary' | 'secondary',
  color: '#fff',
  textAlign: 'center',
  lineHeight: '22px',
  textTransform: 'inherit',
  '&:hover': {
    backgroundColor: '#c3001e',
    color: '#fff',
  },
  [theme.breakpoints.down('sm')]: {
    fontSize: '14px',
    padding: '10px 20px',
  },
  '&:disabled': {
    backgroundColor: 'rgba(0, 0, 102, 0.1)',
    color: ' hwb(240deg 56% 34%)',
  },
});

export const ButtonYellowSmall = styled(Button)({
  backgroundColor: '#F2BF53',
  fontSize: '15px',
  fontWeight: 600,
  borderRadius: '8px',
  padding: '25px 30px',
  color: 'black',
  textAlign: 'center',
  lineHeight: '16px',
  textTransform: 'inherit',
  '&:hover': {
    backgroundColor: '#F2BF53',
    color: '#fff',
  },
  [theme.breakpoints.down('sm')]: {
    fontSize: '14px',
    padding: '18px 20px',
  },
  '&:disabled': {
    backgroundColor: 'rgba(0, 0, 102, 0.1)',
    color: ' hwb(240deg 56% 34%)',
  },
});

export const Buttonblue = styled(Button)({
  backgroundColor: '#000066',
  color: '#fff',
  fontSize: '27px',
  fontWeight: 800,
  padding: '8px 25px',
  borderRadius: '10px',
  filter: 'drop-shadow(5px 5px 16px rgba(0, 0, 0, 0.35))',
  '&:hover': {
    backgroundColor: '#01014a',
  },
});


export const Buttongreysmall = styled(Button)({
  backgroundColor: 'lightgrey',
  fontSize: '15px',
  fontWeight: 600,
  borderRadius: '10px',
  padding: '15px 15px',
  color: 'primary' | 'secondary',
  color: '#000',
  textAlign: 'center',
  lineHeight: '22px',
  textTransform: 'inherit',
  '&:hover': {
    backgroundColor: 'grey',
    color: '#fff',
  },
  '&:focus': {
    backgroundColor: 'green',
    color: '#fff'
  },
  [theme.breakpoints.down('sm')]: {
    fontSize: '12px',
    padding: '10px 20px',
  },
  '&:disabled': {
    backgroundColor: 'rgba(0, 0, 102, 0.1)',
    color: ' hwb(240deg 56% 34%)',
  },
});

export const ButtonredVerySmall = styled(Button)({
  backgroundColor: '#EE0025',
  fontSize: '12px',
  fontWeight: 400,
  borderRadius: '50px',
  padding: '-1px 17px',
  color: 'primary' | 'secondary',
  color: '#fff',
  textAlign: 'center',
  lineHeight: '22px',
  textTransform: 'inherit',
  marginLeft: '10px',
  '&:hover': {
    backgroundColor: '#c3001e',
    color: '#fff',
  },
  [theme.breakpoints.down('sm')]: {
    fontSize: '14px',
    padding: '10px 20px',
  },
  '&:disabled': {
    backgroundColor: 'rgba(0, 0, 102, 0.1)',
    color: ' hwb(240deg 56% 34%)',
  },
});