import React, { useEffect, useState } from 'react';
import '../Style/WealthHeader.scss';
import back from '../Images/backWealth.png';
import { useElements } from '../componentWrapper/context';
import { sendEventToClevertap } from '../../../actions/clevertapActions';
import share from '../Images/share_icon.png';
import cross from '../Images/cross.png';
import BottomSheetPopup from './BottomSheetPopup';
import share_colored from '../Images/share_colored.png'
import refer1 from '../Images/refer1.png'
import refer2 from '../Images/refer2.png'
import copy from '../Images/copy.png'
import { getReferAndEarnCode } from '../../../pages/ReferAndEarn/Redux/actions'
import { navigateToPage } from 'src/components/sharedComponents/bridge/bridge';
import { useSelector } from 'react-redux';
import { generateChecksum } from '../../../utils/helper';

function WealthHeader({ heading, profilePic = false, navigateToProfile, backTo, color }) {
  const { reducer, dispatch } = useElements();
  const { userDetails, userBasicDetails } = reducer;
  const auth_token = localStorage.getItem('auth_token');
  const deviceId = localStorage.getItem('device_id');
  const [showBottom, setShowBottom] = useState(false);
  const referEarnReducer = useSelector((state) => state.referAndEarnReducer);
  const {referAndEarnDetail} = referEarnReducer;

  const [referralCode , setReferralCode] = useState("");
  const [shareData, setShareData] = useState({
    title: 'Check this out!',
    text: '',
    url: '',
    refer_code: '',
  });


  useEffect(() => {
    if (referAndEarnDetail?.refer_code) {
      setReferralCode(referAndEarnDetail?.refer_code?? "STASHER20");
    }
    setShareData({
      title: 'Check this out!',
      // text: referAndEarnDetail?.msg,
      text: `This is my referral code '${referAndEarnDetail?.refer_code}, use this to build your wealth`,
      url: referAndEarnDetail?.refer_url,
      refer_code: referAndEarnDetail?.refer_code,
    });
  }, [referAndEarnDetail]);

  const handleOpenProfile = () => {
    dispatch(
      sendEventToClevertap({
        auth_token,
        eventData: {
          event_name: 'Page_clicked',
          event_property: { Product: 'Wealth', pagename: 'Discovery_page', CTA: 'Profile' },
        },
      }),
    );
    navigateToProfile();
  };

  const handleShareClick = () =>{
    setShowBottom(true);
    let payload = {
      mode: 'reward_club_data',
    };
    const checksum = generateChecksum(payload);
    payload = { ...payload, checksum };
    dispatch(getReferAndEarnCode({ token : auth_token, device_id: deviceId, payload }));
  }

  const handleShareNow = async () => {
    // setShowBottom(false);
    const shareText = `${shareData.title}\n\n${shareData.text}\n${shareData.url}`;
    navigateToPage({ landingPage: 'share_native', type: 'action', data: shareText });
  };

  const copyToClipboard = (textToCopy) => {
    const tempTextArea = document.createElement('textarea' );
    tempTextArea.value = textToCopy;
    document.body.appendChild(tempTextArea);
    tempTextArea.select();
    tempTextArea.setSelectionRange(0, 99999); // For mobile devices

    document.execCommand('copy');
    document.body.removeChild(tempTextArea); 
};

  return (
    <div className={color ? 'wealthHeader_container mehroon' : 'wealthHeader_container'}>
      <div className="header_left">
        <img className="wealthBackBtn" src={back} onClick={backTo}></img>
        <span className="wealthHeading">{heading}</span>
      </div>
      {profilePic && (
        <div className='header_right_share_profile_div'>
          <img src={share} onClick={handleShareClick}></img>
          <div className="header_right" onClick={handleOpenProfile}>
            {(userBasicDetails?.first_name?.length > 0 && userBasicDetails?.first_name[0]) ||
              (userDetails?.firstName?.length > 0 && userDetails?.firstName[0])}
          </div>
        </div>
      )}
      {showBottom &&  <BottomSheetPopup>
        <div className='referral_popup'>
          <div className="cross_div">
            <img
              className="cross"
              src={cross}
              onClick={() => {
                setShowBottom(false);
              }}
            ></img>
          </div>
           <div className='refer_bottom_div'>
              <img src={share_colored} className='share_colored'></img>
              <span className='refer_heading'>Refer your friends & earn!</span>
              <div className='refer_points'>
                <div className='refer_point'>
                  <img className='refer_point_img' src={refer1}></img>
                  <span className='refer_point_text'>Refer your friends and get up to 10,000 Stashcash every month</span>
                </div>
                <div className='refer_point'>
                  <img className='refer_point_img' src={refer2}></img>
                  <span className='refer_point_text'>Invite 1 or more friends by sharing your unique Referral Code</span>
                </div>
              </div>
              <span className='share_code_text'>Share your referral code</span>
              <div className='dotted_code_div'>
                <span className='refer_code'>{referralCode}</span>
                <img className='copy_img' src={copy} onClick={()=>{copyToClipboard(referralCode)}}></img>
              </div>
              <button className='share_now_btn' onClick={handleShareNow}>Share Now</button>
          </div>   
        </div>
      </BottomSheetPopup>}
    </div>
  );
}

export default WealthHeader;
