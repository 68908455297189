import {
  GET_BASICDETAIL,
  GET_BASICDETAIL_SUCCESS,
  GET_BASICDETAIL_ERROR,
  POST_BASICDETAIL,
  POST_BASICDETAIL_SUCCESS,
  POST_BASICDETAIL_ERROR
} from '../../../utils/constant';

export const basicDetailsGet = (get) => {
  return {
    type: GET_BASICDETAIL,
    payload: get,
  };
};

export const basicDetailsgetSuccess = (get) => {
  return {
    type: GET_BASICDETAIL_SUCCESS,
    payload: get,
  };
};

export const basicDetailsgetFail = (error) => {
  return {
    type: GET_BASICDETAIL_ERROR,
    payload: error,
  };
};

export const basicDetailsPost = (post) => {
  return {
    type: POST_BASICDETAIL,
    payload: post,
  };
};

export const basicDetailsPostSuccess = (postSuccess) => {
  return {
    type: POST_BASICDETAIL_SUCCESS,
    payload: postSuccess,
  };
};

export const basicDetailsPostFail = (postError) => {
  return {
    type: POST_BASICDETAIL_ERROR,
    payload: postError,
  };
};
