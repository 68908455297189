import carousalImg1 from '../../assets/images/carousalImg1.png'
import carousalImg2 from '../../assets/images/carousalImg2.png'
import carousalImg3 from '../../assets/images/carousalImg3.png'
import carousalImg4 from '../../assets/images/carousalImg4.png'

export const slides = [
    {
      heading1: 'Your Superpower to',
      heading2: 'get Instant Funds',
      sub_heading: "Get instant credit limit of up to Rs.5 Lakhs with flexible tenure and up to 30 days of interest free period for all your needs",
      img: carousalImg1,
      btnText : "Apply Now",
      points : ["Easy Application" , "Quick Process", "100% Digital"],
      toolTip : "Instant credit line"
    },
    // {
    //   heading1: 'Get Insurance in just',
    //   heading2: 'few steps. Insure now',
    //   sub_heading: "Get access to affordable insurance options ranging from travel to critical illness to fraud protection through uncomplicated journeys",
    //   img: carousalImg2,
    //   btnText : "Get Now",
    //   points : ["Easy Application" , "Quick Process", "100% Digital"],
    //   toolTip : "Hassle free insurance"
    // },
    {
      heading1: 'Best Discounts on Gift',
      heading2: 'Cards of leading brands',
      sub_heading: "Get gift cards of leading brands at great discounts so that you can save while you shop!",
      img: carousalImg3,
      btnText : "Get Now",
      points : ["Best discounts" , "Quick Process", "100% Digital"],
      toolTip : "Best discounts on brands"
    },
    {
      heading1: 'Know your Credit Score',
      heading2: 'in 10 seconds',
      sub_heading: "Monitor your credit score with detailed insights and expert advice on improvement of score",
      img: carousalImg4,
      btnText : "Get Report",
      points : ["10 seconds process" , "Expert Advice", "100% Digital"],
      toolTip : "Check credit score"
    },
  ];

  export const faqs = [
    {
      question: 'How do I apply for a loan?',
      answer: "It's simple! Visit www.stashfin.com or download Stashfin app. Fill in your basic details and click on Proceed. Verify OTP. Fill in mandatory personal and professional fields. Upload your bank statement and other required documents. You will be informed when your loan is approved along with details related to interest rate and tenure."
    },
    {
      question: 'What is the "0% interest for up to 30 days" offer?',
      answer: "If you are eligible for this offer, then every time you transfer funds to your bank account, you can repay the same amount at 0% interest rate before/on your Bill Date. We will be deducting up to 2% as transaction fees and applicable GST upfront on such transfer."

    },
    {
      question: 'Who is eligible to apply?',
      answer: "Any Indian citizen residing in India, above the age of 18, with a proof of current employment, earning a monthly salary of Rs.15,000 and above, a bank account, and a valid identity and address proof can apply."

    },
    {
      question: 'Why is credit score important?',
      answer: "A good CIBIL can help you with the following : 1. Quick Approval of Loans       2. Low-Interest Rate Credits      3. Higher Approval Limits"

    },
    
  ];