import React, { memo, useEffect, useState, useRef } from 'react';
import { Link, useLocation } from 'react-router-dom';
import pic from '../assets/images/logo-stashfin-new.png';
import './header.scss';
import { PATH_WITH_OUT_HEADER_LINKS, PATH_WITH_OUT_HEADER } from './constant';
import HeaderLinks from './HeaderLinks';
import { ARMY_LINK } from '../../../utils/constant';

import ArmyLogo from '../../../assets/images/armyLogo.webp';

const Header = () => {
  const pathname = useLocation()?.pathname;
  const [anchorEl, setAnchorEl] = useState(null);
  const prevAnchorEl = useRef(null);
  const [isHeaderLinks, setIsHeaderLinks] = useState(false);

  useEffect(() => {
    const path = PATH_WITH_OUT_HEADER_LINKS.indexOf(pathname);
    if (path > -1) setIsHeaderLinks(false);
    else setIsHeaderLinks(true);
  }, [pathname]);

  useEffect(() => {
    if (anchorEl) {
      window.addEventListener('scroll', (e) => anchorEl && setAnchorEl(null));
      prevAnchorEl.current = anchorEl;
    } else if (prevAnchorEl.current) {
      window.removeEventListener('scroll');
      prevAnchorEl.current = null;
    }
    return () => prevAnchorEl.current && window.removeEventListener('scroll');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [!anchorEl]);

  return (
    !PATH_WITH_OUT_HEADER.includes(pathname) && (
      <header className={`header ${pathname === ARMY_LINK ? 'army-header' : ''}`}>
        <div className="container">
          <div className="row flex-row alignCenter">
            <div
              className={`${
                pathname === ARMY_LINK
                  ? 'col-xl-10 col-lg-10 col-md-10 col-sm-6 col-6'
                  : 'col-xl-2 col-lg-2 col-md-6 col-sm-6 col-8'
              } logo`}
            >
              {isHeaderLinks || pathname === ARMY_LINK ? (
                <Link to="/">
                  <img src={pic} />
                </Link>
              ) : (
                <img src={pic} />
              )}
            </div>
            {isHeaderLinks && <HeaderLinks anchorEl={anchorEl} setAnchorEl={setAnchorEl} />}
            {pathname === ARMY_LINK && (
              <div className="col-xl-2 col-lg-2 col-md-2 col-sm-6 col-6">
                <div className="d-flex justify-content-end">
                  <img src={ArmyLogo} alt="Stashfin Sentinel" />
                </div>
              </div>
            )}
          </div>
        </div>
      </header>
    )
  );
};

export default memo(Header);
