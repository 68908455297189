import {
  GET_WEALTH_LEAD_DETAILS_SUCCESS,
  GET_WEALTH_LEAD_DETAILS_ERROR,
  WEALTH_SUBMIT_FORM_SUCCESS,
  WEALTH_SUBMIT_FORM_ERROR,
  GET_WEALTH_LEAD_DETAILS,
  CLOSE_SUCCESS_POPUP,
} from './actionTypes';

const initialState = {
  token: '',
  deviceId: '',
  utmData: {},
  loader: true,
  leadDetails: {},
  error: null,
  formSubmit: false,
  isSuccessPopupOpen: false,
};

const WealthWaitlistReducer = (state = initialState, action = {}) => {
  const { type, payload } = action;
  switch (type) {
    case GET_WEALTH_LEAD_DETAILS:
      return {
        ...state,
        token: payload?.token,
        deviceId: payload?.deviceId,
        utmData: payload?.utmData,
      };

    case GET_WEALTH_LEAD_DETAILS_SUCCESS:
      console.log("sdfghjk", payload)
      return {
        ...state,
        loader: false,
        leadDetails: payload,
      };

    case GET_WEALTH_LEAD_DETAILS_ERROR:
      return {
        ...state,
        loader: false,
        error: payload?.error,
      };

    case WEALTH_SUBMIT_FORM_SUCCESS:
      return {
        ...state,
        formSubmit: true,
        isSuccessPopupOpen: true
      };

    case WEALTH_SUBMIT_FORM_ERROR:
      return {
        ...state,
        error: payload?.error,
      };

    case CLOSE_SUCCESS_POPUP:
      return {
        ...state,
        isSuccessPopupOpen: false
      };

    default:
      console.log(state)
      return state;
  }
};

export default WealthWaitlistReducer;
